<template>
  <div>
    <t-dropdown variant="smActions">
      <template
        v-slot:trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
      >
        <button
          aria-label="Trip Completed Dropdown"
          :class="`trip-status-pill-button ${getVariant} focus:outline-none`"
          style="height: 35px; width: 170px"
          aria-haspopup="true"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <span>{{ getText }}</span>
          <template v-if="$acl.canUpdate('transaction')"
            ><i v-if="!refundStatus" class="fas fa-chevron-down"
          /></template>
        </button>
      </template>

      <template
        v-slot:default="{ hide }"
        v-if="!refundStatus || !penalizedStatus"
      >
        <div
          v-if="$acl.canUpdate('transaction')"
          @click="hide()"
          class="py-1 mt-1 bg-white rounded-md shadow-md"
        >
          <t-dropdown-item
            v-if="!refundStatus"
            @click="$emit('refund')"
            class="text-oOrange hover:bg-orange-100"
          >
            Refund
          </t-dropdown-item>
          <t-dropdown-item
            v-if="!penalizedStatus"
            @click="$emit('penalize-policy')"
            class="text-oRed hover:bg-red-100"
          >
            Policy Penalty
          </t-dropdown-item>
        </div>
      </template>
    </t-dropdown>
  </div>
</template>

<script>
import TDropdownItem from '@/components/dropdown/TDropdownItem'

export default {
  name: 'TripCompletedDropdown',
  components: {
    TDropdownItem,
  },

  props: {
    statusForced: {
      type: Boolean,
      default: false,
    },
    statusForcedBySystem: {
      type: Boolean,
      default: false,
    },
    refundStatus: {
      type: Boolean,
      default: false,
    },
    penalizedStatus: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    getText() {
      if (this.statusForced && this.statusForcedBySystem) {
        return 'Trip Completed'
      } else if (this.statusForced && this.statusForcedBySystem === false) {
        return 'Force Completed'
      } else return 'Trip Completed'
    },
    getVariant() {
      if (this.statusForced && this.statusForcedBySystem) {
        return 'bg-green-500 text-white'
      } else if (this.statusForced && this.statusForcedBySystem === false) {
        return 'red'
      } else return 'green'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/trip-status';
</style>
