<template>
  <slide-over-right :id="sorId" v-on:showModal="showModal">
    <loading :active.sync="isLoading"></loading>

    <template v-slot:header>
      <div class="flex items-center justify-between">
        <h2 class="font-semibold text-gray-900 text-22px">
          {{ titleText }}
        </h2>
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex items-center justify-end mt-6">
        <slide-over-right-trigger :id="sorId">
          <anchor-button variant="secondary">Cancel</anchor-button>
        </slide-over-right-trigger>
        <t-button
          type="submit"
          @click="$refs.submitButton.click()"
          class="ml-3"
          >{{ actionButtonText }}</t-button
        >
      </div>
    </template>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)" class="px-6">
        <div class="flex items-center">
          <div class="w-1/2 pl-2">
            <text-input
              rules="required"
              label="Pricing Name"
              v-model="form.name"
            />
          </div>

          <div class="w-1/2 pl-2">
            <ValidationProvider
              name="Vehicle Type"
              rules="required"
              v-slot="{ errors }"
            >
              <input-label :error="errors[0]" text="Vehicle Type *" />
              <t-rich-select
                name="options"
                :options="vehicleTypes"
                valueAttribute="value"
                textAttribute="text"
                :hideSearchBox="true"
                :variant="{ danger: !!errors[0] }"
                v-model="form.bike_category"
              />
              <div class="">
                <input-error-item :message="errors[0]" />
              </div>
            </ValidationProvider>
          </div>
        </div>

        <div class="flex items-center">
          <div class="w-1/2 pl-2 mt-2">
            <ValidationProvider
              name="Fleet"
              rules="required"
              v-slot="{ errors }"
            >
              <input-label :error="errors[0]" text="Fleet *" />
              <t-rich-select
                name="options"
                :options="billingFleets"
                valueAttribute="id"
                textAttribute="name"
                :hideSearchBox="false"
                :variant="{ danger: !!errors[0] }"
                v-model="form.fleet"
              />
              <div class="mt-4">
                <input-error-item :message="errors[0]" />
              </div>
            </ValidationProvider>
          </div>

          <div class="w-1/2 pl-2">
            <AppInput
              rules=""
              label="Fleet Currency"
              :value="selectedFleetCurrency || 'Please select a fleet'"
              disabled
            />
            <div class="h-2"></div>
          </div>
        </div>

        <div class="flex items-center mt-2">
          <div class="w-1/2 pl-2">
            <text-input
              rules="required|between:0,1000"
              :label="
                `Vehicle Reservation Price` + selectedFleetCurrencyFormatted
              "
              v-model.number="form.vehicle_reservation_fees"
            />
          </div>

          <div class="w-1/2 pl-2">
            <text-input
              type="number"
              rules="required"
              step="0.01"
              :label="`Unlock Charge` + selectedFleetCurrencyFormatted"
              v-model.number="form.vehicle_unlock_fees"
            />
          </div>
        </div>

        <div class="flex items-center mt-2" v-if="rentalEnabled">
          <div class="w-full pl-2">
            <text-input
              rules="required|between:0,1000"
              :label="
                `Vehicle Rental Price/hour` + selectedFleetCurrencyFormatted
              "
              v-model.number="form.vehicle_rental_per_hour_fees"
            />
          </div>
        </div>

        <div class="flex items-center pt-2 pl-2">
          <ValidationProvider
            vid="tax_enabled"
            name=" "
            rules=""
            v-slot="{ errors }"
          >
            <input-label :error="errors[0]" text="Tax Plan*" />
            <div class="flex">
              <label class="flex items-center mt-px ml-2 cursor-pointer">
                <input
                  v-model="useTaxPlanField"
                  type="radio"
                  class="p-1 form-radio"
                  name="useTaxPlanField"
                  :value="true"
                />
                <span class="ml-2 text-sm">Yes</span>
              </label>

              <label class="flex items-center mt-px ml-4 cursor-pointer">
                <input
                  v-model="useTaxPlanField"
                  type="radio"
                  class="p-1 form-radio"
                  name="useTaxPlanField"
                  :value="false"
                />
                <span class="ml-2 text-sm">No</span>
              </label>
            </div>
          </ValidationProvider>
        </div>

        <div class="flex items-center pt-6" v-if="useTaxPlanField">
          <div class="w-full pl-2">
            <ValidationProvider
              :name="`Tax Plan`"
              :rules="useTaxPlanField ? 'required' : ''"
              v-slot="{ errors }"
            >
              <input-label :error="errors[0]" text="LOAD TAX PLAN" />
              <t-rich-select
                name="options"
                :options="taxPlans"
                valueAttribute="id"
                textAttribute="name"
                :hideSearchBox="false"
                v-model="form.tax_plan"
              />
              <div class="mt-4">
                <input-error-item :message="errors[0]" />
              </div>
            </ValidationProvider>
          </div>
        </div>

        <!-- uc -->

        <div class="flex items-center pt-4">
          <div class="w-1/2 pl-2">
            <text-input
              rules="required"
              type="number"
              step="1"
              label="Billing Unit (Mins)"
              v-model.number="form.billing_unit_mins"
            />
          </div>
          <div class="w-1/2 pl-2">
            <text-input
              rules="required"
              type="number"
              step="0.01"
              :label="`First Unit Fees` + selectedFleetCurrencyFormatted"
              v-model.number="form.first_unit_fees"
            />
          </div>
        </div>

        <div class="flex items-center">
          <div class="w-1/2 pl-2">
            <text-input
              rules="required"
              type="number"
              step="0.01"
              :label="`Default Unit Fees` + selectedFleetCurrencyFormatted"
              v-model.number="form.default_unit_fees"
            />
          </div>
          <div class="w-1/2 pl-2">
            <text-input
              rules="required"
              type="number"
              step="0.01"
              label="Max Billing Per Ride"
              v-model.number="form.max_billing_per_ride"
            />
          </div>
        </div>
        <div class="flex items-center">
          <div class="w-1/2 pl-2">
            <text-input
              type="number"
              step="0.01"
              label="Min Billing Per Ride"
              v-model.number="form.minimum_ride_fee"
            />
          </div>
          <div class="w-1/2 pl-2">
            <text-input
              type="number"
              step="0.01"
              label="Min Billing Per Rental"
              v-model.number="form.minimum_rental_fee"
            />
          </div>
        </div>

        <div class="flex items-center pt-4 pb-5 pl-2">
          <ValidationProvider
            vid="status"
            name=" "
            rules=""
            v-slot="{ errors }"
          >
            <input-label :error="errors[0]" text="Status *" />
            <div class="flex">
              <label class="flex items-center mt-px ml-2 cursor-pointer">
                <input
                  type="radio"
                  class="p-1 form-radio"
                  name="status"
                  v-model="form.status_active"
                  :value="true"
                />
                <span class="ml-2 text-sm">Active</span>
              </label>

              <label class="flex items-center mt-px ml-4 cursor-pointer">
                <input
                  type="radio"
                  class="p-1 form-radio"
                  name="status"
                  v-model="form.status_active"
                  :value="false"
                />
                <span class="ml-2 text-sm">Inactive</span>
              </label>
            </div>
          </ValidationProvider>
        </div>

        <div class="flex items-center pt-4 pb-5 pl-2">
          <ValidationProvider
            vid="pause_ride_fee_same_as_billing"
            name=" "
            rules=""
            v-slot="{ errors }"
          >
            <input-label
              :error="errors[0]"
              text="Is the pause ride fee same as above? *"
            />
            <div class="flex">
              <label class="flex items-center mt-px ml-2 cursor-pointer">
                <input
                  type="radio"
                  class="p-1 form-radio"
                  name="pause_ride_fee_same_as_billing"
                  v-model="form.pause_ride_fee_same_as_billing"
                  :value="true"
                />
                <span class="ml-2 text-sm">Yes</span>
              </label>

              <label class="flex items-center mt-px ml-4 cursor-pointer">
                <input
                  type="radio"
                  class="p-1 form-radio"
                  name="pause_ride_fee_same_as_billing"
                  v-model="form.pause_ride_fee_same_as_billing"
                  :value="false"
                />
                <span class="ml-2 text-sm">No</span>
              </label>
            </div>
          </ValidationProvider>
        </div>

        <div
          class="flex items-center pt-2"
          v-if="!form.pause_ride_fee_same_as_billing"
        >
          <div class="w-1/2 pl-2">
            <text-input
              rules="required"
              type="number"
              step="1"
              label="Pause Billing Unit (Mins)"
              v-model.number="form.pause_billing_unit_mins"
            />
          </div>
          <div class="w-1/2 pl-2">
            <text-input
              rules="required"
              type="number"
              step="0.01"
              label="Pause First Unit Fees"
              v-model="form.pause_first_unit_fees"
            />
          </div>
        </div>

        <div
          class="flex items-center"
          v-if="!form.pause_ride_fee_same_as_billing"
        >
          <div class="w-1/2 pl-2">
            <text-input
              rules="required"
              type="number"
              step="0.01"
              :label="`Pause Next Unit Fees` + selectedFleetCurrencyFormatted"
              v-model="form.pause_default_unit_fees"
            />
          </div>
          <div class="w-1/2 pl-2">
            <!-- <ValidationProvider
              vid="tax_applies_to_pause_fees"
              name=" "
              rules=""
              v-slot="{ errors }"
            >
              <input-label :error="errors[0]" text="Tax applied on pauses?" />
              <div class="flex">
                <input-group></input-group>
                <label class="flex items-center mt-px ml-2 cursor-pointer">
                  <input
                    type="radio"
                    class="p-1 form-radio"
                    name="pause_ride_fee_same_as_billing"
                    :checked="form.tax_applies_to_pause_fees"
                    @click="form.tax_applies_to_pause_fees = true"
                  />
                  <span class="ml-2 text-sm">Yes</span>
                </label>
                <label class="flex items-center mt-px ml-4 cursor-pointer">
                  <input
                    type="radio"
                    class="p-1 form-radio"
                    name="tax_enabled"
                    :checked="!form.tax_applies_to_pause_fees"
                    @click="form.tax_applies_to_pause_fees = false"
                  />
                  <span class="ml-2 text-sm">No</span>
                </label>
              </div>
            </ValidationProvider> -->

            <ValidationProvider
              vid="Tax applied on pauses"
              name="Tax applied on pauses"
              rules=""
              v-slot="{ errors }"
            >
              <AppFormLabel :error="errors[0]" text="Tax applied on pauses?" />
              <TRichSelect
                v-model="form.tax_applies_to_pause_fees"
                :options="[
                  { text: 'Yes', value: true },
                  { text: 'No', value: false },
                ]"
                :placeholder="`Select one`"
                :value-attribute="`value`"
                :text-attribute="`text`"
                :variant="{ danger: !!errors[0] }"
                hide-search-box
              />
              <AppFormError :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>

        <div class="flex items-center pt-4 pb-5 pl-2">
          <ValidationProvider
            vid="is_pre_auth_required"
            name=" "
            rules=""
            v-slot="{ errors }"
          >
            <input-label :error="errors[0]" text="Is pre auth required? *" />
            <div class="flex">
              <label class="flex items-center mt-px ml-2 cursor-pointer">
                <input
                  type="radio"
                  class="p-1 form-radio"
                  name="is_pre_auth_required"
                  v-model="form.is_pre_auth_required"
                  :value="true"
                />
                <span class="ml-2 text-sm">Yes</span>
              </label>

              <label class="flex items-center mt-px ml-4 cursor-pointer">
                <input
                  type="radio"
                  class="p-1 form-radio"
                  name="is_pre_auth_required"
                  v-model="form.is_pre_auth_required"
                  :value="false"
                />
                <span class="ml-2 text-sm">No</span>
              </label>
            </div>
          </ValidationProvider>
        </div>

        <div class="flex items-center pt-2" v-if="form.is_pre_auth_required">
          <div class="w-1/2 pl-2">
            <text-input
              rules="required"
              type="number"
              label="Pre auth hold amount *"
              v-model.number="form.pre_auth_hold_amount"
            />
          </div>
          <div class="w-1/2 pl-2">
            <ValidationProvider
              name="Pre auth ride condition"
              rules="required"
              v-slot="{ errors }"
            >
              <input-label :error="errors[0]" text="Pre auth ride condition*" />
              <t-rich-select
                name="pre_auth_ride_condition"
                :options="rideTypeChoice"
                valueAttribute="value"
                textAttribute="name"
                :hideSearchBox="true"
                :variant="{ danger: !!errors[0] }"
                v-model="form.pre_auth_ride_condition"
              />
              <div class="">
                <input-error-item :message="errors[0]" />
              </div>
            </ValidationProvider>
          </div>
        </div>

        <div class="w-full pl-2">
          <input-label text="Billing Description"></input-label>
          <t-textarea name="billingDescription" v-model="form.description" />
          <input-error-item />
        </div>

        <button type="submit" ref="submitButton" class="hidden">Save</button>
      </form>
    </ValidationObserver>
  </slide-over-right>
</template>

<script>
import xMan from '@/utils/xMan'
import { EventBus } from '@/utils/EventBus'
// SubscriptionConfig
import { BillingPlanConfig } from '@/config/BillingPlanConfig'
import { DropdownConfig } from '@/config/DropdownConfig'
import SlideOverRight from '@/components/modals/SlideOverRight'
import SlideOverRightTrigger from '@/components/modals/SlideOverRightTrigger'
import AnchorButton from '@/components/form/AnchorButton.vue'
import TextInput from '../../components/form/TextInput.vue'
import InputLabel from '@/components/form/InputLabel'

export default {
  name: 'AddBillingPlan',
  components: {
    SlideOverRight,
    SlideOverRightTrigger,
    AnchorButton,
    TextInput,
    InputLabel,
  },
  props: {
    vehicleTypes: Array,
  },
  data() {
    return {
      rentalEnabled: false,
      isLoading: false,
      billingFleets: [],
      taxPlans: [],
      item: {},
      form: {},
      errors: [],
      sorId: BillingPlanConfig.events.sorId,
      useTaxPlanField: 'no',
    }
  },
  watch: {
    form: {
      handler: async function(updatedForm) {
        this.form.tax_enabled = updatedForm.tax_plan ? true : false
        if (!this.isEditing) {
          if (
            updatedForm.bike_category == 'S' ||
            updatedForm.bike_category == 'P' ||
            updatedForm.bike_category == 'E'
          ) {
            await this.loadBillingFleets()
          }
        }
        // console.log("Watch Fired...");
        // console.log(
        //   this.form.tax_enabled,
        //   this.form.bike_category,
        //   this.billingFleets.length
        // );
      },
      deep: true,
    },
  },
  async created() {
    if (this.$org?.subscription?.rental_enabled) {
      this.rentalEnabled = true
    }
    await this.loadBillingFleets()

    this.taxPlans = await this.$http
      .get(DropdownConfig.api.taxPlans)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
  },
  computed: {
    isEditing: function() {
      // enabled status-switch by default
      return Object.keys(this.item).length > 4
    },
    titleText: function() {
      return this.isEditing ? 'Edit Billing Plan' : 'Add Billing Plan'
    },
    actionButtonText: function() {
      return this.isEditing ? 'Update' : 'Save'
    },
    vehicleTypeChoice: function() {
      return [
        {
          name: 'Scooter',
          value: 'P',
        },
        {
          name: 'Bike',
          value: 'S',
        },
        {
          name: 'E-Bike',
          value: 'E',
        },
        {
          name: 'SuperCoco',
          value: 'C',
        },
        {
          name: 'Kenota',
          value: 'K',
        },
      ]
    },
    rideTypeChoice: function() {
      return [
        {
          name: 'First Ride',
          value: 'FIRST_RIDE',
        },
        {
          name: 'All Ride',
          value: 'ALL_RIDE',
        },
      ]
    },
    selectedFleet() {
      return this.form.fleet
        ? this.billingFleets.find((fleet) => fleet.id == this.form.fleet)
        : null
    },
    selectedFleetCurrency() {
      return this.selectedFleet
        ? this.selectedFleet?.country?.currency_symbol
        : null
    },
    selectedFleetCurrencyFormatted() {
      return this.selectedFleetCurrency ? `(${this.selectedFleetCurrency})` : ''
    },
  },
  mounted() {
    EventBus.$on(BillingPlanConfig.events.editingData, (item) => {
      console.log('ii', item)
      this.item = item

      if (this.item.existing_fleet) {
        const includesFleet = this.billingFleets.some((elem) => {
          return (
            JSON.stringify(this.item.existing_fleet) === JSON.stringify(elem)
          )
        })
        if (!includesFleet) {
          console.log('-------- Propagating Existing Fleet into Edit --------')
          this.billingFleets.push(this.item.existing_fleet)
        }
        delete this.item.existing_fleet
      }

      if (item.pause_ride_fee_same_as_billing == null) {
        item.pause_ride_fee_same_as_billing = true
      }
      if (item.is_pre_auth_required == null) {
        item.is_pre_auth_required = true
      }

      if (item.tax_applies_to_pause_fees == null) {
        item.tax_applies_to_pause_fees = false
      }

      if (item.tax_plan) {
        this.useTaxPlanField = true
      } else {
        this.useTaxPlanField = false
      }

      this.form = {
        ...this.item,
      }

      console.log('tp', this.form.tax_plan, 'hideTp=', this.useTaxPlanField)
    })
    // console.log({ s: this.isEditing });
  },
  methods: {
    showModal() {
      dispatchEvent(new Event(BillingPlanConfig.events.sorToggle))
    },

    loadBillingFleets: async function() {
      this.billingFleets = await this.$http
        .get(DropdownConfig.api.billingFleets(this.form.bike_category))
        .then((res) => res.data.data)
        .catch((err) => console.log(err))
    },
    submit: async function() {
      this.isLoading = true

      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? BillingPlanConfig.api.update(this.item.id)
        : BillingPlanConfig.api.create

      const formDataProxy = { ...this.form }

      if (!this.useTaxPlanField) {
        formDataProxy.tax_plan = ''
      }

      if (this.form.default_currency)
        formDataProxy.default_currency = this.form.default_currency.id

      console.log('form = ', formDataProxy)

      let data = new xMan(formDataProxy).toFormData()

      let message = this.isEditing
        ? 'Billing plan updated successfully'
        : 'Billing plan added successfully'

      try {
        let response = await this.$http({ method, url, data })

        this.isLoading = false
        // Close the slideover
        dispatchEvent(new Event(BillingPlanConfig.events.sorClose))
        // Refetch the indexData
        dispatchEvent(new Event(BillingPlanConfig.events.refresh))
        // Reset the form
        this.form = {}
        this.$notify(
          {
            group: 'generic',
            type: 'success',
            title: 'Success',
            text: message,
          },
          3000
        )
        // Resolved
        return response.status
      } catch (err) {
        this.isLoading = false
        console.error('Error occured!', err.response)
        const e = err?.response.data
        const max = Object.keys(e).length
        let t = max * 2000 // in ms

        for (const k in e) {
          this.$notify(
            {
              group: 'bottomLeft',
              type: 'error',
              title: `Error Occured`,
              text: k + ': ' + e[k],
            },
            t
          )
          t -= 2000
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.feature-item {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e2e8f0;
  padding: 4px 0; // 15px 0?

  & .left {
    width: 65%;
    padding-right: 0.5rem;
    font-size: 0.875rem;
  }

  & .right {
    width: 35%;
    padding-left: 0.5rem;
  }
}
</style>
