var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mb-2 mr-3 bg-white rounded-md shadow flex flex-col flex-grow "},[(_vm.riderTabs.length > 0)?_c('tabs',{attrs:{"items":_vm.riderTabs},on:{"tabChange":_vm.onTabChange}}):_vm._e(),_c('div',{staticClass:"flex w-full pl-3 trip-detail md-max:pl-0 md-max:block"},[_c('div',{staticClass:"block w-3/5 px-10 pt-10 pb-0 md-max:px-8 md-max:w-full md-max:h-48 trip-map"},[_c('div',{staticClass:"flex pt-2 md-max:pt-6"},[_c('start-place-icon'),(_vm.activeTrip)?_c('div',{staticClass:"grid items-center ml-2"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"mr-2 time"},[_vm._v(_vm._s(_vm.start_date))]),(
                  !_vm.activeTrip.drop_off_location && _vm.activeTrip.status === 'O'
                )?[_c('x-status',{ref:"onTripStatusCompn",attrs:{"text":_vm.onTripStatusText,"variant":_vm.onTripStatusVariant,"profile":"trip"}})]:_vm._e(),(
                  !_vm.activeTrip.drop_off_location && _vm.activeTrip.status === 'R'
                )?[_c('x-status',{attrs:{"text":"Reserved","variant":"blue","profile":"trip"}})]:_vm._e()],2),(_vm.canceled)?_c('div',{staticClass:"canceled-body"},[_c('span',[_vm._v("Cancelled")])]):_vm._e()]),_c('span',{staticClass:"mt-2 location"},[_vm._v(_vm._s(_vm.activeTrip.pick_up_location))])]):_vm._e()],1),_vm._m(0),_c('div',{staticClass:"flex items-center pt-2"},[_c('end-place-icon'),(_vm.activeTrip)?_c('div',{staticClass:"grid items-center ml-2"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"mr-2 time"},[_vm._v(_vm._s(_vm.end_date))]),(_vm.activeTrip.status === 'A')?[_c('x-status',{attrs:{"text":"Cancelled","variant":"red","profile":"trip"}})]:_vm._e()],2),(_vm.canceled)?_c('div',{staticClass:"canceled-body"},[_c('span',[_vm._v("Cancelled")])]):_vm._e()]),_c('span',{staticClass:"mt-2 location"},[_vm._v(_vm._s(_vm.activeTrip.drop_off_location))])]):_vm._e()],1)]),_c('div',{staticClass:"grid w-2/5 grid-cols-2 md-max:w-full md-max:h-48"},_vm._l((_vm.categories),function(item,index){return _c('div',{key:index,staticClass:"items-center grid-item place-content-center"},[_c('trip-muti-icon',{attrs:{"item":item}})],1)}),0)]),(!_vm.activeTrip)?_c('div',{staticClass:"w-full flex-grow"},[_vm._v("   ")]):_vm._e(),(_vm.activeTrip)?_c('div',{staticClass:"w-full flex-grow relative"},[_c('gmap-map',{ref:"map",staticStyle:{"width":"100%","height":"100%"},attrs:{"zoom":_vm.zoom,"center":_vm.center,"map-type-id":"terrain"},on:{"click":_vm.onTabMap}},[_c('gmap-marker',{attrs:{"position":_vm.getLatLng(_vm.activeTrip.src_coord),"icon":{
          url: require("@/assets/img/start_place.png"),
          scaledSize: { width: 63, height: 73 },
        }}}),(!_vm.activeTrip.drop_off_location && _vm.activeTrip.status === 'O')?_c('gmap-marker',{attrs:{"position":_vm.getLatLng(_vm.activeTrip.bike.current_loc_coord),"icon":{
          url: require("@/assets/icon/status/vehicle-active.svg"),
          scaledSize: { width: 40, height: 40 },
        }}}):_vm._e(),(_vm.getTripPath(_vm.activeTrip).length)?_c('div',_vm._l((_vm.getTripPath(_vm.activeTrip)),function(item){return _c('gmap-marker',{key:item.created_at,attrs:{"position":_vm.getLatLng(item.location),"icon":{
            url: require("@/assets/img/icons/map_marker@3x.png"),
            scaledSize: { width: 30, height: 30 },
          }}})}),1):_vm._e(),(_vm.activeTrip.dest_coord)?_c('gmap-marker',{attrs:{"position":_vm.getLatLng(_vm.activeTrip.dest_coord),"icon":{
          url: require("@/assets/img/end_place.png"),
          scaledSize: { width: 63, height: 73 },
        }}}):_vm._e(),(_vm.getParkingAreas.length > 0 && _vm.parkingMarkerOpen)?_vm._l((_vm.getParkingAreas),function(area,index){return _c('gmap-marker',{key:index,attrs:{"position":_vm.getLatLng(area.center_coords),"icon":{
            url: require("@/assets/img/areas_pin/P_icon.png"),
            scaledSize: { width: 45, height: 55 },
          }},on:{"click":function($event){return _vm.toggleMarker(area, -1, 'P')}}})}):_vm._e(),(_vm.getRestrictedAreas.length > 0 && _vm.restrictedMarkerOpen)?_vm._l((_vm.getRestrictedAreas),function(area,index){return _c('gmap-marker',{key:index,attrs:{"position":_vm.getLatLng(area.center_coords),"icon":{
            url: require("@/assets/img/areas_pin/R_icon.png"),
            scaledSize: { width: 45, height: 55 },
          }},on:{"click":function($event){return _vm.toggleMarker(area, -1, 'R')}}})}):_vm._e(),(_vm.getSlowSpeedAreas.length > 0 && _vm.slowSpeedMarkerOpen)?_vm._l((_vm.getSlowSpeedAreas),function(area,index){return _c('gmap-marker',{key:index,attrs:{"position":_vm.getLatLng(area.center_coords),"icon":{
            url: require("@/assets/img/areas_pin/S_icon.png"),
            scaledSize: { width: 45, height: 55 },
          }},on:{"click":function($event){return _vm.toggleMarker(area, -1, 'S')}}})}):_vm._e(),_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){_vm.infoWinOpen = false}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.infoContent)}})])],2),(_vm.getMarkerControlCounter > 0)?_c('div',{staticClass:"area-info"},[_c('div',{staticClass:"flex flex-wrap items-center justify-between px-4 area-body"},[(_vm.getParkingAreas.length > 0)?_c('button',{staticClass:"flex items-center justify-between mx-1",on:{"click":function($event){_vm.parkingMarkerOpen = !_vm.parkingMarkerOpen}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/img/areas_pin/P_icon.png"),"width":"20px"}}),_vm._v(" "+_vm._s(_vm.parkingMarkerOpen ? 'Hide' : 'Show')+" ")]):_vm._e(),(_vm.getRestrictedAreas.length > 0)?_c('button',{staticClass:"flex items-center justify-between mx-1",on:{"click":function($event){_vm.restrictedMarkerOpen = !_vm.restrictedMarkerOpen}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/img/areas_pin/R_icon.png"),"width":"20px"}}),_vm._v(" "+_vm._s(_vm.restrictedMarkerOpen ? 'Hide' : 'Show')+" ")]):_vm._e(),(_vm.getSlowSpeedAreas.length > 0)?_c('button',{staticClass:"flex items-center justify-between mx-1",on:{"click":function($event){_vm.slowSpeedMarkerOpen = !_vm.slowSpeedMarkerOpen}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/img/areas_pin/S_icon.png"),"width":"20px"}}),_vm._v(" "+_vm._s(_vm.slowSpeedMarkerOpen ? 'Hide' : 'Show')+" ")]):_vm._e()])]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative arrow"},[_c('i',{staticClass:"fas fa-caret-down"})])}]

export { render, staticRenderFns }