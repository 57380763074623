<template>
  <t-modal
    :name="`pauseHistoryModal`"
    :variant="`2xl`"
    :hideCloseButton="true"
    :disableBodyScroll="true"
    ref="popup"
  >
    <div class="px-3 py-4">
      <div>
        <h3 class="text-xl text-gray-400 font-medium">
          Total Pause Duration :
        </h3>
        <h1 class="text-2xl text-blue-500 font-semibold">
          {{ getDuration(tripDetail.pause_time_secs_till_now) }}
        </h1>
      </div>
      <div class="mt-5">
        <div class="grid grid-cols-3">
          <div class="bg-gray-100 border border-gray-200 p-2 font-semibold">
            Paused at
          </div>
          <div class="bg-gray-100 border border-gray-200 p-2 font-semibold">
            Unpaused at
          </div>
          <div class="bg-gray-100 border border-gray-200 p-2 font-semibold">
            Duration
          </div>
        </div>

        <div
          class="grid grid-cols-3 history-container"
          :key="`pause-history-${itemIndex}`"
          v-for="(pause_entry, itemIndex) in tripDetail.pause_entries"
        >
          <div class=" border border-gray-200 p-2 ">
            <span class="text-gray-900 font-medium">{{
              getTime(pause_entry.start_time)
            }}</span>
            <br />
            <span class="text-gray-600">{{
              getDate(pause_entry.start_time)
            }}</span>
          </div>
          <div class="border border-gray-200 p-2 ">
            <span class="text-gray-900 font-medium">{{
              getTime(pause_entry.end_time)
            }}</span>
            <br />
            <span class="text-gray-600">{{
              getDate(pause_entry.end_time)
            }}</span>
          </div>
          <div class="border border-gray-200 p-2">
            <span v-if="pause_entry.duration_secs > 0">{{
              getDuration(pause_entry.duration_secs)
            }}</span>
            <span v-else class="text-green-600 font-semibold">{{
              getDuration(pause_entry.duration_secs)
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </t-modal>
</template>
<script>
import {
  getHumanReadableDuration,
  getFormattedDateTime,
} from '@/utils/datetime'
export default {
  name: 'PauseHistoryModal',
  props: {
    tripDetail: {
      type: Object,
    },
  },
  methods: {
    getDuration(...args) {
      if (args[0] > 0) {
        return getHumanReadableDuration(...args)
      } else {
        const currentTime =
          this.tripDetail.pause_time_secs_till_now -
          this.getTotalPassedPauseDuration()
        return getHumanReadableDuration(currentTime)
      }
    },
    getTotalPassedPauseDuration() {
      const passedPausedHistory = this.tripDetail.pause_entries.filter(
        (ph) => ph.end_time !== null
      )
      let totalPassedPauseDuration = passedPausedHistory.reduce(
        (sum, ph) => (sum += ph.duration_secs),
        0
      )
      console.log(
        'totalPassedPauseDuration',
        this.tripDetail.pause_time_secs_till_now - totalPassedPauseDuration
      )

      return totalPassedPauseDuration
    },
    getTime(clock) {
      return this.$UTCAwareTime(clock, {
        format: 'hh:mm:ss a',
      })
    },
    getDate(clock) {
      return getFormattedDateTime(clock, 'D MMM, YYYY')
    },
  },
}
</script>
<style lang="scss">
.history-container {
  &:nth-child(odd) {
    background-color: #f7fafc;
  }
}
</style>
