<template>
  <div class="w-full overflow-x-auto overflow-y-hidden">
    <div class="flex flex-wrap tabs-inner-wrapper mt-1">
      <div
        :class="{
          'tab-item px-4 py-4 cursor-pointer font-bold uppercase': true,
          'text-gray-500': !item.isActive,
          'text-oDark tab-item-active': item.isActive,
        }"
        v-for="(item, index) in items"
        :key="`tab-${index}`"
        @click="onTabClick(item)"
      >
        {{ item.title }}
      </div>
    </div>
    <!-- <div class="h-1 bg-gray-100 shadow-2xl" /> -->
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  components: {},
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onTabClick(item) {
      if (item.isActive) {
        return
      }

      this.$emit('tabChange', item.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.tabs-inner-wrapper {
  border-bottom: 2px solid #f6f6f6;
}

.tab-item {
  border-bottom: 2px solid transparent;
  &:hover {
    border-bottom-color: #000;
  }
}

.tab-item-active {
  border-bottom-color: #000;
}
</style>
