<template lang="">
  <t-modal
    :name="`pricingPlanHistoryModal`"
    :variant="`lg`"
    :hideCloseButton="true"
    :disableBodyScroll="true"
    ref="popup"
  >
    <div class="px-5 py-4">
      <div class="item">
        <div>
          <p
            class="font-bold text-xl text-blue-600 capitalize cursor-pointer"
            @click="showPricingPlan"
          >
            {{ tripDetail.pricing_plan.name }}
          </p>
          <p class="text-sm text-gray-500">Pricing Plan</p>
        </div>
        <div>
          <p class="font-bold text-xl ">
            <RouterLink
              :to="{
                name: 'ViewFleetProfile',
                params: { id: tripDetail.pricing_plan.fleet.id },
              }"
              target="_blank"
              class="text-blue-600 capitalize"
            >
              {{ tripDetail.pricing_plan.fleet.name }}
            </RouterLink>
          </p>
          <p class="text-sm text-gray-500">Fleet</p>
        </div>
      </div>
      <hr class="my-2" />
      <div>
        <h2 class="title">Pricing :</h2>
        <!-- unlock charge -->
        <div>
          <div v-if="isDiscountApplied">
            <div class="item">
              <p>Unlock Charge :</p>
              <p>
                <span
                  class="text-gray-500 mr-3"
                  v-if="
                    getFloatValue(tripDetail.unlock_charge) <
                      getFloatValue(tripDetail.original_unlock_cost)
                  "
                  ><del
                    >{{ getCurrencySymbol
                    }}{{ getFloatValue(tripDetail.original_unlock_cost) }}</del
                  ></span
                >
                <span
                  >{{ getCurrencySymbol
                  }}{{ getFloatValue(tripDetail.unlock_charge) }}</span
                >
              </p>
            </div>
          </div>
          <div v-else>
            <div class="item">
              <p>Unlock Charge :</p>
              <p>
                <span
                  >{{ getCurrencySymbol
                  }}{{ getFloatValue(tripDetail.unlock_charge) }}</span
                >
              </p>
            </div>
          </div>
        </div>
        <!-- reservation charge -->
        <div v-if="tripDetail.is_reservation_trip">
          <div v-if="isDiscountApplied">
            <div class="item">
              <p>Reservation Charge :</p>
              <p>
                <span
                  class="text-gray-500 mr-3"
                  v-if="
                    getFloatValue(tripDetail.reservation_charge) <
                      getFloatValue(tripDetail.original_reservation_cost)
                  "
                  ><del
                    >{{ getCurrencySymbol
                    }}{{
                      getFloatValue(tripDetail.original_reservation_cost)
                    }}</del
                  ></span
                >
                <span
                  >{{ getCurrencySymbol
                  }}{{ getFloatValue(tripDetail.reservation_charge) }}</span
                >
              </p>
            </div>
          </div>
          <div v-else>
            <div class="item">
              <p>Reservation Charge :</p>
              <p>
                <span
                  >{{ getCurrencySymbol
                  }}{{ getFloatValue(tripDetail.reservation_charge) }}</span
                >
              </p>
            </div>
          </div>
        </div>
        <!-- per minute charge -->
        <div>
          <div
            v-if="
              isDiscountApplied &&
                tripDetail.discounted_rate_per_minute !== null
            "
          >
            <div class="item">
              <p>Per Minute Charge :</p>
              <p>
                <span
                  class="text-gray-500 mr-3"
                  v-if="
                    getFloatValue(tripDetail.discounted_rate_per_minute) <
                      getFloatValue(tripDetail.rate_per_minute)
                  "
                  ><del
                    >{{ getCurrencySymbol
                    }}{{ getFloatValue(tripDetail.rate_per_minute) }}</del
                  ></span
                >
                <span
                  >{{ getCurrencySymbol
                  }}{{
                    getFloatValue(tripDetail.discounted_rate_per_minute)
                  }}</span
                >
              </p>
            </div>
          </div>
          <div v-else>
            <div class="item">
              <p>Per Minute Charge :</p>
              <p>
                <span
                  >{{ getCurrencySymbol
                  }}{{ getFloatValue(tripDetail.rate_per_minute) }}</span
                >
              </p>
            </div>
          </div>
        </div>
        <div class="item">
          <p>First Unit Fees :</p>
          <p>
            <span
              >{{ getCurrencySymbol
              }}{{ getFloatValue(tripDetail.first_unit_fees) }}</span
            >
          </p>
        </div>
        <div
          v-if="getFloatValue(tripDetail.default_unit_fees) > 0"
          class="item"
        >
          <p>Default Unit Fees :</p>
          <p>
            {{ getCurrencySymbol
            }}{{ getFloatValue(tripDetail.default_unit_fees) }}
          </p>
        </div>
        <div v-if="getFloatValue(tripDetail.pause_cost) > 0" class="item">
          <p>Pause Ride Charge :</p>
          <p>
            {{ getCurrencySymbol }}{{ getFloatValue(tripDetail.pause_cost) }}
          </p>
        </div>
      </div>
      <div v-if="haveAnyLimit">
        <h2 class="title">Limit :</h2>
        <div
          v-if="getFloatValue(tripDetail.max_billing_amount) > 0"
          class="item"
        >
          <p>Max Billing Per Ride :</p>
          <p>
            {{ getCurrencySymbol
            }}{{ getFloatValue(tripDetail.max_billing_amount) }}
          </p>
        </div>
        <div
          v-if="getFloatValue(tripDetail.pre_auth_hold_amount) > 0"
          class="item"
        >
          <p>Pre Authorized Hold Amount :</p>
          <p>
            {{ getCurrencySymbol
            }}{{ getFloatValue(tripDetail.pre_auth_hold_amount) }}
          </p>
        </div>
        <div
          v-if="getFloatValue(tripDetail.pre_auth_captured_amount) > 0"
          class="item"
        >
          <p>Pre Authorized Capture Amount :</p>
          <p>
            {{ getCurrencySymbol
            }}{{ getFloatValue(tripDetail.pre_auth_captured_amount) }}
          </p>
        </div>
      </div>
    </div>
  </t-modal>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'PricingPlanHistoryModal',
  props: {
    tripDetail: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('auth', ['organizationInfo']),
    getCurrencySymbol() {
      return this.organizationInfo?.default_currency?.symbol || '$'
    },
    isDiscountApplied() {
      if (
        this.tripDetail.promo_applied ||
        this.tripDetail.voucher_applied ||
        this.tripDetail.pass_applied
      ) {
        return true
      } else {
        return false
      }
    },
    haveAnyLimit() {
      if (
        this.getFloatValue(this.tripDetail.max_billing_amount) > 0 ||
        this.getFloatValue(this.tripDetail.pre_auth_hold_amount) > 0 ||
        this.getFloatValue(this.tripDetail.pre_auth_captured_amount) > 0
      ) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    getFloatValue(value) {
      if (value) {
        return parseFloat(value).toFixed(2)
      } else {
        return parseFloat(0).toFixed(2)
      }
    },
    showPricingPlan() {
      this.$emit('showPricingPlan', this.tripDetail.pricing_plan.id)
    },
  },
}
</script>
<style lang="scss">
.title {
  @apply font-bold text-xl text-gray-700 mb-2 mt-4;
}
.item {
  @apply flex justify-between items-center my-1;
}
</style>
