<template>
  <div>
    <PauseHistoryModal :tripDetail="tripDetail" />
    <PricingPlanHistoryModal
      :tripDetail="tripDetail"
      @showPricingPlan="showPricingPlan($event)"
    />
    <add-edit-billing-plan :vehicleTypes="getVehicleTypes" />
    <section
      class="
        w-full
        px-4
        py-3
        mb-2
        bg-white
        rounded-md
        shadow
        responsive-header-section
      "
    >
      <div class="flex flex-wrap items-center w-full h-full">
        <div class="items-center left-trip">
          <div
            class="items-center place-content-center back-arrow"
            @click="goBack"
          >
            <i class="fas fa-arrow-left"></i>
          </div>
          <span>Trip Details (ID: #{{ tripDetail.id | onlyLastFive }})</span>
          <span
            v-if="tripDetail.child_trips.length > 0"
            class="group-trip-badge"
            >Group Trip</span
          >
          <span id="myDiv" ref="myDiv"></span>
          <div
            v-if="tripDetail.pause_time_secs_till_now > 0"
            class="flex items-center"
          >
            <div class="mr-2"><img src="@/assets/icon/pause_ride.svg" /></div>
            <div>
              <div class="flex items-center">
                <h4
                  class="font-bold text-oDark text-14px duration-text  mb-1"
                  :class="tripDetail.is_paused ? `text-oGreen` : `text-oDark`"
                >
                  {{ getDuration(tripDetail.pause_time_secs_till_now) }}
                </h4>
                <!-- <div
                  class="cursor-pointer"
                  @click="onPauseHistoryModalReq(true)"
                >
                  
                </div> -->
                <img
                  src="@/assets/icon/info.svg"
                  class="cursor-pointer"
                  @click="onPauseHistoryModalReq()"
                />
              </div>
              <h2 class="text-gray-500 text-14px ">
                Trip Paused
              </h2>
            </div>
          </div>
        </div>

        <div class="block lg:flex right-trip lg:items-center">
          <div class="ml-2">
            <button
              @click="$emit('download-invoice')"
              class="
                flex
                items-center
                justify-between
                px-4
                py-0
                font-medium
                border
                rounded-full
                shadow-sm
                text-14px text-oCharcoal
                focus:border-oCharcoal
                hover:border-oCharcoal
                focus:outline-none
              "
              style="height: 35px"
            >
              <div class="flex items-center">
                <svg
                  class="w-6 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                  ></path>
                </svg>
                <span class="text-gray-700"> Download Invoice </span>
              </div>
            </button>
          </div>
          <!-- <div class="flex items-center ml-2">
            <ExportTripBlock />
          </div> -->

          <div>
            <template class="flex items-center mt-2 ml-2 lg:mt-0">
              <template
                v-if="
                  tripDetail.status === 'C' || tripDetail.status_forced === true
                "
              >
                <TripCompletedBlock
                  @refund="$emit('refund')"
                  @penalize-policy="$emit('penalize-policy')"
                  :status-forced="tripDetail.status_forced"
                  :status-forced-by-system="tripDetail.status_forced_by_system"
                  :refund-status="isRefunded"
                  :penalized-status="isPenalized"
                />
              </template>

              <template v-else-if="tripDetail.status === 'O'">
                <OnTripBlock
                  @force-completed="$emit('force-completed')"
                  :tripId="tripDetail.id"
                />
              </template>
              <template v-else>
                <TripStatusPillButton :status="tripDetail.status" />
              </template>
            </template>
          </div>
        </div>
      </div>
    </section>

    <section
      class="
        flex flex-col
        items-start
        justify-start
        w-full
        px-4
        py-3
        mb-2
        space-y-5
        bg-white
        rounded-md
        shadow
        md:space-y-0 md:items-center md:flex-row md:justify-between
        responsive-header-section
      "
    >
      <div class="flex items-center w-full space-x-3 md:w-1/3 ">
        <DisplayAvatar
          :avatar="tripDetail.user.profile_pic"
          :width="`68px`"
          :height="`68px`"
        />
        <div>
          <router-link
            v-if="tripDetail.user"
            :to="{
              name: 'ViewRiderUserProfile',
              params: { id: tripDetail.user.id },
            }"
            target="_blank"
            class="cursor-pointer"
          >
            <span class="font-bold text-oDark text-14px">
              {{ tripDetail.user.full_name || '--' }}
            </span>
            <span class="rider-type-badge">Owner</span>
          </router-link>

          <div class="flex items-center mt-1 text-gray-500 text-14px mt-2">
            <i class="mr-2 text-lg fas fa-phone fa-flip-horizontal" />
            <span>{{ tripDetail.user.phone_number || '--' }}</span>
          </div>
        </div>
      </div>

      <div
        class="
          grid
          w-full
          grid-cols-2
          gap-y-3
          md:w-2/3 md:grid-cols-4 md:gap-y-0
        "
      >
        <div class="">
          <h2 class="font-bold text-gray-500 text-14px mb-2">Trip ID</h2>
          <h4 class="font-bold text-oDark text-14px">
            {{ $truncate(tripDetail.id, -5, '#') || '--' }}
          </h4>
        </div>

        <!-- <div class="" v-if="tripIsOnRide">
          <h2 class="font-bold text-gray-500 text-14px mb-2">
            Current Pause Duration
          </h2>
          <h4 ref="pauseDuration" class="font-bold text-oDark text-14px">--</h4>
        </div> -->

        <div class="">
          <h2 class="font-bold text-gray-500 text-14px mb-2">Duration</h2>
          <div class="flex items-center">
            <h4
              class="font-bold text-oDark text-14px duration-text mr-5"
              :class="tripDetail.status === 'O' ? `text-oGreen` : `text-oDark`"
            >
              {{ getDuration(tripDetail.riding_time) }}
            </h4>
          </div>
        </div>

        <div class="">
          <h2 class="font-bold text-gray-500 text-14px mb-2">Battery</h2>
          <div class="flex items-center">
            <h4 class="font-bold text-oDark text-14px duration-text mr-5">
              {{ getBatteryLine(tripDetail) }}
            </h4>
            <div class="flex items-center">
              <battery-status
                :level="getEndBatteryLevel(tripDetail)"
                height="15px"
                width="30px"
                textClass="hidden"
              />
            </div>
          </div>
        </div>

        <div>
          <h2 class="font-bold text-gray-500 text-14px mb-2">Vehicle QR</h2>

          <div class="flex items-center">
            <router-link
              v-if="tripDetail.bike"
              :to="{
                name: 'ViewVehicleProfile',
                params: { id: tripDetail.bike.id },
              }"
              target="_blank"
            >
              <h4 class="font-bold text-oLink text-14px">
                {{ tripDetail.bike.qr_code || '--' }}
              </h4>
            </router-link>

            <h4 v-else class="text-blue-500 text-14px">--</h4>
            <trip-duration-dropdown
              class="ml-3"
              :isGuestTrip="false"
              :tripId="tripDetail.id"
              :onRide="getIsOnRideStatus(tripDetail.status)"
              :lockedStatus="tripDetail.is_vehicle_locked"
              @forceCompleted="$emit('force-completed')"
            />
          </div>
        </div>
      </div>
    </section>

    <section
      class="
        flex flex-col
        items-start
        justify-start
        w-full
        px-4
        py-3
        mb-2
        space-y-5
        bg-white
        rounded-md
        shadow
        md:space-y-0 md:items-center md:flex-row md:justify-between
        responsive-header-section
      "
      v-for="(childItem, childItemIndex) in tripDetail.child_trips"
      :key="childItemIndex"
    >
      <div class="flex items-center w-full space-x-3 md:w-1/3">
        <DisplayAvatar
          :avatar="childItem.user.profile_pic"
          :width="`68px`"
          :height="`68px`"
        />
        <div>
          <router-link
            v-if="childItem.user"
            :to="{
              name: 'ViewRiderUserProfile',
              params: { id: childItem.user.id },
            }"
            class="cursor-pointer"
            target="_blank"
          >
            <span class="font-bold text-oDark text-14px">
              {{ childItem.user.full_name || '--' }}
            </span>
            <span class="rider-type-badge">Guest</span>
          </router-link>

          <div class="flex items-center mt-1 text-gray-500 text-14px mt-2">
            <i class="mr-2 text-lg fas fa-phone fa-flip-horizontal" />
            <span>{{ childItem.user.phone_number || '--' }}</span>
          </div>
        </div>
      </div>

      <div
        class="
          grid
          w-full
          grid-cols-2
          gap-y-3
          md:w-2/3 md:grid-cols-4 md:gap-y-0
        "
      >
        <div class="">
          <h2 class="font-bold text-gray-500 text-14px mb-2">Trip ID</h2>
          <h4 class="font-bold text-oDark text-14px">
            {{ $truncate(childItem.id, -5, '#') || '--' }}
          </h4>
        </div>

        <!-- <div class="" v-if="tripIsOnRide">
          <h2 class="font-bold text-gray-500 text-14px mb-2">
            Current Pause Duration
          </h2>
          <h4 ref="pauseDuration" class="font-bold text-oDark text-14px">--</h4>
        </div> -->

        <div class="">
          <h2 class="font-bold text-gray-500 text-14px mb-2">Duration</h2>
          <div class="flex items-center">
            <h4
              class="font-bold text-oDark text-14px duration-text mr-5"
              :class="childItem.status === 'O' ? `text-oGreen` : `text-oDark`"
            >
              {{ getDuration(childItem.riding_time) }}
            </h4>
          </div>
        </div>

        <div class="">
          <h2 class="font-bold text-gray-500 text-14px mb-2">Battery</h2>
          <div class="flex items-center">
            <h4 class="font-bold text-oDark text-14px duration-text mr-5">
              {{ getBatteryLine(childItem) }}
            </h4>
            <div class="flex items-center">
              <battery-status
                :level="getEndBatteryLevel(childItem)"
                height="15px"
                width="30px"
                textClass="hidden"
              />
            </div>
          </div>
        </div>

        <div>
          <h2 class="font-bold text-gray-500 text-14px mb-2">Vehicle QR</h2>

          <div class="flex items-center">
            <router-link
              v-if="childItem.bike"
              :to="{
                name: 'ViewVehicleProfile',
                params: { id: childItem.bike.id },
              }"
              target="_blank"
            >
              <h4 class="font-bold text-oLink text-14px">
                {{ childItem.bike.qr_code || '--' }}
              </h4>
            </router-link>

            <h4 v-else class="text-blue-500 text-14px">--</h4>
            <trip-duration-dropdown
              class="ml-3"
              :isGuestTrip="true"
              :tripId="childItem.id"
              :onRide="getIsOnRideStatus(childItem.status)"
              :lockedStatus="childItem.is_vehicle_locked"
              @forceCompleted="$emit('force-completed')"
            />
          </div>
        </div>
      </div>
    </section>

    <div class="grid w-full grid-cols-1 md:grid-cols-5">
      <div class="col-span-1 flex flex-col md:col-span-3">
        <trip-google-map-view
          v-if="renderOnTripStatus"
          :detail="tripDetail"
          :onTripStatusText="onTripStatusText"
          :onTripStatusVariant="onTripStatusVariant"
          ref="tripGmapView"
          id="tripGmapView"
        />
      </div>

      <div class="col-span-1 md:col-span-2 ">
        <trip-cost-view
          :key="`trip_cost-${tripDetail.riding_time}`"
          :detail="tripDetail"
          :symbol="symbol"
        />
      </div>
    </div>

    <div class="grid w-full grid-cols-1 md:grid-cols-5">
      <div class="col-span-1 md:col-span-3">
        <ReportBlock :detail="tripDetail" />
      </div>

      <div class="col-span-1 md:col-span-2 flex flex-col">
        <trip-point-view :detail="tripDetail" />
      </div>
    </div>

    <div class="grid w-full grid-cols-1 md:grid-cols-5">
      <div class="col-span-1 md:col-span-3">
        <!-- notes -->
        <TripNoteView :id="tripDetail.id" />
        <!-- /notes -->
      </div>

      <!-- tags -->
      <div class="col-span-1 md:col-span-2">
        <TripTagView :id="tripDetail.id" />
        <div class="py-3 px-8 mb-2 mr-3 bg-white rounded-md shadow w-full">
          <div v-if="getFlags(tripDetail).length > 0">
            <div class="flex items-center">
              <img src="@/assets/icon/flag.svg" class="w-5 h-5" />
              <p class="text-lg font-extrabold ml-2">
                Flags
              </p>
            </div>

            <div class="flex flex-wrap items-center mt-4">
              <template v-for="(flag, flagIndex) in getFlags(tripDetail)">
                <trip-flag-icon
                  v-tooltip.bottom="getFlagAlias(flag, tripDetail)"
                  size="lg"
                  class="mr-1"
                  :name="flag"
                  :key="`trip-flag-${flagIndex}`"
                />
              </template>
            </div>
          </div>
          <div v-else>No Flags</div>
        </div>
      </div>
      <!-- /tags -->
    </div>

    <!-- <trip-timeline-header /> -->
    <!-- <trip-timeline-main :trip-detail="tripDetail" /> -->
    <TripTimeline :tripId="tripDetail.id" />
  </div>
</template>

<script>
// import TripTimelineHeader from '@/composites/trip/details/blocks/timeline/TripTimelineHeader'
// import TripTimelineMain from '@/composites/trip/details/blocks/timeline/TripTimelineMain'

import TripGoogleMapView from './blocks/TripGoogleMapView'
import TripCostView from './blocks/TripCostView'

import TripPointView from './blocks/TripPointView'
import TripNoteView from './blocks/note/TripNoteView'
import TripTagView from './blocks/TripTagView'
import TripDurationDropdown from '../shared/TripDurationDropdown.vue'

import { mapGetters } from 'vuex'
// import ExportAsDropdown from "@/components/dropdown/ExportAsDropdown.vue";
import TripStatusPillButton from '@/components/button/TripStatusPillButton.vue'
// import TripCompletedDropdown from "./details/TripCompletedDropdown.vue";
// import OnTripDropdown from "./details/OnTripDropdown.vue";
// import ExportTripBlock from '@/composites/trip/details/blocks/ExportTripBlock.vue'
import TripCompletedBlock from '@/composites/trip/details/blocks/TripCompletedBlock.vue'
import OnTripBlock from '@/composites/trip/details/blocks/OnTripBlock.vue'

import TripTimeline from '@/composites/trip/details/TripTimeline.vue'
import { SocketConfig } from '@/config/SocketConfig'

import ReconnectingWebsocket from 'reconnectingwebsocket'
import {
  getHumanReadableDuration,
  getFormattedDateTime,
} from '@/utils/datetime'
import PauseHistoryModal from './PauseHistoryModal.vue'
import PricingPlanHistoryModal from './PricingPlanHistoryModal.vue'
import AddEditBillingPlan from '@/views/pricing/BillingPlanAddEdit.vue'
import { EventBus } from '@/utils/EventBus'
import { BillingPlanConfig } from '@/config/BillingPlanConfig'
export default {
  components: {
    TripGoogleMapView,
    TripCostView,
    TripPointView,
    TripNoteView,
    TripTagView,
    // ExportAsDropdown,
    TripStatusPillButton,
    // TripCompletedDropdown,
    // OnTripDropdown,
    // ExportTripBlock,
    TripCompletedBlock,
    OnTripBlock,
    AddEditBillingPlan,
    DisplayAvatar: () => import('@/components/ui/DisplayAvatar'),
    ReportBlock: () => import('./blocks/ReportBlock'),
    // TripTimelineHeader,
    // TripTimelineMain,
    TripTimeline,
    BatteryStatus: () => import('@/components/badge/BatteryStatus'),
    TripFlagIcon: () => import('@/components/badge/TripFlagIcon'),
    TripDurationDropdown,
    PauseHistoryModal,
    PricingPlanHistoryModal,
  },
  props: {
    tripDetail: {
      required: false,
      default: null,
    },
  },

  computed: {
    ...mapGetters('auth', ['organizationInfo']),
    getInvoiceId() {
      return this.$truncate(this.tripDetail?.invoice?.id, -5, '#') || '--'
    },
    getVehicleTypes() {
      let vehicleTypes = []
      const vehicleTypeMap = {
        'SCOOTER': {
          text: 'Scooter',
          value: 'P',
        },
        'SCOOTER PRO': {
          text: 'Scooter Pro',
          value: 'PP',
        },
        'EBIKE': {
          text: 'E-Bike',
          value: 'E',
        },
        'BIKE': {
          text: 'Bike',
          value: 'S',
        },
        'COCO': {
          text: 'SuperCoco',
          value: 'C',
        },
        'KENOTA': {
          text: 'Kenota',
          value: 'K',
        },
      }
      const orgVehicleTypes = this?.$org?.vehicle_type || []
      orgVehicleTypes.forEach((t) => vehicleTypes.push(vehicleTypeMap[t]))
      console.log('vehicleTypes', vehicleTypes)
      return vehicleTypes
    },
    getInvoiceDate() {
      return this.tripDetail?.invoice?.date
        ? getFormattedDateTime(this.tripDetail?.invoice?.date, 'D MMM h:m:ss a')
        : '--'
    },
    lastTripStatus() {
      return this.$store.getters['lastTrip/getStatus']
    },
    tripIsOnRide() {
      return this.tripDetail.status === 'O'
    },
    isRefunded() {
      return this.tripDetail?.invoice && this.tripDetail?.invoice?.is_refunded
    },
    isPenalized() {
      return this.tripDetail.penalties.length > 0
    },
  },
  data() {
    return {
      onTripDurationStr: null,
      vehicleTypes: [],
      statuses: {
        R: 'Reserved',
        A: 'Cancelled',
        U: 'Starting',
        O: 'On Trip',
        V: 'Forced',
        C: 'Completed',
        T: 'Time Out',
      },
      symbol: '$',
      intervalid: null,
      pauseIntervalid: null,
      sockListener: null,
      onTripStatusText: 'On Trip',
      onTripStatusVariant: 'purple',
      renderOnTripStatus: true,
    }
  },
  // watch: {
  //   onTripDurationStr(updated) {
  //     console.log('tmrender = ', updated.data.duration)
  //   },
  // },
  mounted() {
    this.symbol = this.organizationInfo.default_currency
      ? this.organizationInfo.default_currency.symbol
      : '$'
    console.log('symbol = ', this.symbol)
    if (this.tripDetail.status === 'O') {
      console.log('Starting Bike Noti Listener')
      this.startListener()

      if (this.tripDetail.is_paused) {
        // let pauseData = this.tripDetail.pause_entries.pop()
        // this.pauseIntervalid = setInterval(() => {
        //   this.refreshPauseTimer(pauseData)
        // }, 1000)

        this.onTripStatusText = 'On Pause'
        this.onTripStatusVariant = 'yellow'
      } else {
        this.onTripStatusText = 'On Trip'
        this.onTripStatusVariant = 'purple'
      }
    }
    // if (!this.detail.drop_off_location && this.lastTripStatus === 'O')
    // this.$nextTick(() => {
    // })
  },
  beforeDestroy() {
    this.removeListener()
    this.removeOnTripTimer()
  },
  methods: {
    async showPricingPlan(id) {
      console.log('showPricingPlan', id)

      let billingDetails = await this.$http
        .get(BillingPlanConfig.api.details(id))
        .then((res) => res.data)
        .catch((err) => console.log(err))
      billingDetails.tax_plan = billingDetails.tax_plan
        ? billingDetails.tax_plan.id
        : null
      billingDetails.existing_fleet = billingDetails.fleet
      billingDetails.fleet = billingDetails.fleet
        ? billingDetails.fleet.id
        : null
      console.log('Morphed...', billingDetails)

      EventBus.$emit(BillingPlanConfig.events.editingData, billingDetails)
      dispatchEvent(new Event(BillingPlanConfig.events.sorToggle))
    },
    getFlagAlias(flag, data = {}) {
      let aliases = {
        was_paused: 'Vehicle was paused',
        legally_parked: 'Vehicle was legally Parked',
        illegally_parked: 'Vehicle was illegally Parked',
        good_rated: 'Good Rating',
        bad_rated: 'Bad Rating',
        inside_service_area: 'Inside Service Area',
        outside_service_area: 'Outside Service Area',
        restricted_area: 'Inside Restricted Area',
        slowzone_area: 'Inside Slowzone Area',
        voucher_applied: 'Voucher Applied',
        promo_applied: 'Promo Applied',
        pass_applied: 'Pass Applied',
        rider_balance_negative: 'Rider Balance Negative',
        trigger_payment_applied: `Triggered ${data.trigger_payment_status &&
          `${this.getTriggerStatus(data.trigger_payment_status)}`}`,
        trigger_payment_failed: `Triggered ${data.trigger_payment_status &&
          `${this.getTriggerStatus(data.trigger_payment_status)}`}`,
      }

      return flag in aliases ? aliases[flag] : '--'
    },
    getTriggerStatus(status) {
      let text = 'Status: '
      if (status === 'I') {
        text = text.concat('Initiated')
      }
      if (status === 'S') {
        text = text.concat('Succeeded')
      }
      if (status === 'IF') {
        text = text.concat('Initiation Failed')
      }
      if (status === 'FF') {
        text = text.concat('Finalization Failed')
      }
      return text
    },
    getFlags(item) {
      let flags = []

      // rating
      if (item.received_good_feedback === true) {
        flags.push('good_rated')
      }

      if (item.received_good_feedback === false) {
        flags.push('bad_rated')
      }

      // parking flag
      if (item.vehicle_was_legally_parked === true) {
        flags.push('legally_parked')
      }
      if (item.vehicle_was_legally_parked === false) {
        flags.push('illegally_parked')
      }

      //geofence alert
      if (item.went_outside_geofence === true) {
        flags.push('outside_service_area')
      }
      if (item.went_outside_geofence === false) {
        flags.push('inside_service_area')
      }
      if (item.went_inside_restricted_area === true) {
        flags.push('restricted_area')
      }
      if (item.went_inside_slowzone_area === true) {
        flags.push('slowzone_area')
      }

      // pause flag
      if (item.was_paused === true) {
        flags.push('was_paused')
      }

      //Offer
      if (item.voucher_applied === true) {
        flags.push('voucher_applied')
      }
      if (item.promo_applied === true) {
        flags.push('promo_applied')
      }
      if (item.pass_applied === true) {
        flags.push('pass_applied')
      }

      //payment
      if (item.rider_balance_negative === true) {
        flags.push('rider_balance_negative')
      }
      if (
        item.rider_balance_negative === true &&
        item.triggered_negative_balance_resolution === true
      ) {
        if (
          item.trigger_payment_status === 'IF' ||
          item.trigger_payment_status === 'FF'
        ) {
          flags.push('trigger_payment_failed')
        } else {
          flags.push('trigger_payment_applied')
        }
      }
      return flags
    },
    onPauseHistoryModalReq() {
      this.$modal.show('pauseHistoryModal')
    },
    getIsOnRideStatus(tripStatus) {
      return tripStatus === 'O'
    },
    getEndBatteryLevel(trip) {
      const vehicle = trip.bike
      let finalEndLevel = trip.end_power_level

      if (vehicle && (!finalEndLevel || trip.status !== 'C')) {
        finalEndLevel = vehicle.battery_charge
      }

      finalEndLevel = parseInt(finalEndLevel, 10)

      return finalEndLevel
    },
    getBatteryLine(trip) {
      const vehicle = trip.bike
      let finalEndLevel = trip.end_power_level
      let finalStartLevel = trip.start_power_level

      if (vehicle && (!finalEndLevel || trip.status !== 'C')) {
        finalEndLevel = vehicle.battery_charge
      }

      if (!finalStartLevel) {
        finalStartLevel = 0
      } else {
        finalStartLevel = parseInt(finalStartLevel, 10)
      }

      finalEndLevel = parseInt(finalEndLevel, 10)

      return `${finalStartLevel} -> ${finalEndLevel}%`
    },
    getDuration(...args) {
      return getHumanReadableDuration(...args)
    },
    removeOnTripTimer() {
      if (this.intervalid) clearInterval(this.intervalid)
      if (this.pauseIntervalid) clearInterval(this.pauseIntervalid)
    },
    startListener() {
      let token = localStorage.getItem('token') || null
      // console.log(`Fetched Token : ${token}`)
      if (!token) return
      let sockUrl =
        SocketConfig.baseUrl +
        SocketConfig.channels.bikeNotifications +
        `?token=${token}`

      this.sockListener = this.sockListener
        ? this.sockListener
        : new ReconnectingWebsocket(sockUrl, null, SocketConfig.configs())

      this.sockListener.onopen = () => {
        console.log('Opened Noti Connection...')
      }
      this.sockListener.onclose = () => {
        console.log('Closed Noti Connection...')
      }
      this.sockListener.onmessage = this.onMessageHandler
    },
    removeListener() {
      if (this.sockListener) {
        this.sockListener.close(
          4003,
          'oto system closed the socket as the was closed'
        )
        this.sockListener = null
      }
    },
    onTripStatusforceRerender() {
      // Remove my-component from the DOM
      this.renderOnTripStatus = false

      this.$nextTick(() => {
        // Add the component back in
        this.renderOnTripStatus = true
      })
    },
    onMessageHandler(message) {
      console.log('Incoming --> ', message)
      let payload = JSON.parse(message.data)
      if (payload.n_type !== 'noti.bike_noti') return

      let bikeId = payload.bike_id
      if (!bikeId) return
      if (this.tripDetail.bike.id !== bikeId) return

      console.log(`Incoming data for bike ${bikeId} : data -->`, payload.data)
      if ('trip_paused' in payload.data) {
        // console.log('Received pause socket msg', payload.data.trip_paused)
        // console.log('Received pause socket data', {
        //   ...payload.data.pause_data,
        // })
        let tripPaused = payload.data.trip_paused
        // let pauseData = { ...payload.data.pause_data }
        this.tripDetail.is_paused = tripPaused

        if (tripPaused) {
          // this.pauseIntervalid = setInterval(() => {
          //   this.refreshPauseTimer(pauseData)
          // }, 1000)
          this.onTripStatusText = 'On Pause'
          this.onTripStatusVariant = 'yellow'
        } else {
          // clearInterval(this.pauseIntervalid)
          // this.$refs.pauseDuration.innerHTML = '--'
          this.onTripStatusText = 'On Trip'
          this.onTripStatusVariant = 'purple'
        }
        this.onTripStatusforceRerender()
      }

      // if (data.status_flags) {
      //   console.log(
      //     `Incoming data for bike ${bikeId} : on ride status --> ${data.status_flags.is_on_ride}`
      //   )
      //   if (!data.status_flags.is_on_ride) {
      //     this.removeListener()
      //     this.$emit('fetchTripDetail', this.detail.id)
      //     return
      //   }
      // }
      // console.log(
      //   `Incoming data for bike ${bikeId} : on ride status --> ${data.status_flags.is_on_ride}`
      // )
      // if (this.detail.bike.current_loc_coord === data['location']) {
      //   console.log(
      //     `Skipping updates : Has same data --> ${this.detail.bike.current_loc_coord}`
      //   )
      // } else {
      //   const prevData = this.detail.bike.current_loc_coord
      //   this.detail.bike.current_loc_coord = data['location']
      //   console.log(
      //     `Updated data for bike ${bikeId} | location : ${prevData} --> ${data['location']}`
      //   )
      // }
      // this.boundingExtenders = this.getMapBoundExtenders()
    },
    goBack() {
      this.$router.push('/trips')
    },
    getCalculatedTimerStartTime() {
      let tripStatus = this.tripDetail.status
      if (tripStatus == 'O') {
        return new Date(this.tripDetail.pick_up_time)
      } else if (tripStatus == 'R') {
        return new Date(this.tripDetail.reservation_start_time)
      } else {
        return null
      }
    },
    getTimerStr(timerStartTime) {
      let now = new Date()
      // console.log('now = ', now)
      // let offsetSecs = now.getTimezoneOffset() * 60
      var diffTimestamp = now - timerStartTime
      // console.log('tmr = ', diffTimestamp)

      var delta = Math.abs(diffTimestamp) / 1000
      // delta += offsetSecs
      var days = Math.floor(delta / 86400)
      delta -= days * 86400
      var hours = Math.floor(delta / 3600) % 24
      delta -= hours * 3600
      var minutes = Math.floor(delta / 60) % 60
      delta -= minutes * 60
      var seconds = Math.floor(delta % 60)

      // console.log(diffTimestamp, delta, days, hours, minutes, seconds)

      let tmStr = ''
      if (days > 0) {
        tmStr += `${days} d `
      }
      if (hours > 0) {
        tmStr += `${hours} h `
      }
      if (minutes > 0) {
        tmStr += `${minutes} m `
      }
      if (seconds > 0) {
        tmStr += `${seconds} s`
      }
      return tmStr
    },
    refreshPauseTimer(entry) {
      if (!entry.start_time) {
        return
      }
      let tmStr = this.getTimerStr(new Date(entry.start_time))
      // document.getElementById('onTripDurationStrSpan').innerHTML = tmStr
      // this.onTripDurationStr = tmStr
      this.$refs.pauseDuration.innerHTML = `${tmStr}`
      // this.$forceUpdate()
      // console.log('tmr = ', tmStr, this.$refs.myDiv)
      // console.log('tmr = ', tmStr)
    },
  },
}
</script>

<style lang="scss" scoped>
.group-trip-badge {
  background-color: #2563eb !important;
  color: #fff;
  font-weight: 400 !important;
  font-size: 12px !important;
  text-transform: uppercase;
  padding: 4px 10px;
  border-radius: 14px;
  margin-left: 15px !important;
}

.duration-text {
  min-width: 80px;
}

.rider-type-badge {
  @apply text-oLink;

  background-color: #e9efff;
  font-weight: 400 !important;
  font-size: 9.75px !important;
  text-transform: uppercase;
  padding: 2px 6px;
  border-radius: 8px;
  margin-left: 10px !important;
}
</style>
