var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mb-2 bg-white rounded-md shadow overflow-auto"},[_c('div',{staticClass:"relative"},[_c('div',{staticClass:"items-center w-full h-full place-content-center flex"},[_c('div',{staticClass:"my-6"},[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"block cost-total-title"},[_vm._v(_vm._s(_vm.detail.currency.symbol)+" "+_vm._s(_vm.getTotalAmount)+" ")]),_c('img',{staticClass:"cursor-pointer ml-1",attrs:{"src":require("@/assets/icon/info.svg")},on:{"click":function($event){return _vm.onPricingPlanHistoryModalReq()}}})])])]),(_vm.isRefunded)?[_vm._m(0)]:_vm._e(),_c('TripNotesView',{attrs:{"data":_vm.detail}})],2),_c('div',{staticClass:"row"},[_c('div',{staticClass:"grid h-24 grid-cols-2"},[_c('div',{staticClass:"pl-10 place-content-center cost-grid-item border-right flex-col text-left"},[_c('div',{staticClass:"cost-title-no-color text-gray-500"},[_vm._v(" "+_vm._s(_vm.items[0].title)+" ")]),_c('div',{staticClass:"cost-title-no-color text-oDark"},[_vm._v(" "+_vm._s(_vm.items[0].subTitle)+" ")])]),_c('div',{staticClass:"pr-10 place-content-center cost-grid-item flex-col text-right"},[_c('div',{staticClass:"cost-title-no-color text-gray-500"},[_vm._v(" "+_vm._s(_vm.items[1].title)+" ")]),_c('div',{staticClass:"cost-title-no-color text-oDark"},[_vm._v(" "+_vm._s(_vm.items[1].subTitle)+" ")])])])]),_c('div',{staticClass:"px-6"},[_vm._l((_vm.getCostCategories),function(category,index){return _c('div',{key:index,staticClass:"cost-list"},[_c('div',{class:{ 'col-w-6': true, 'flex': category.id === 'discount' }},[_c('span',{class:{
            'cost-title': true,
            'flex-grow': category.id === 'discount',
          },style:(category.id === 'discount' ? 'color:#3ebc30' : 'color:#000000')},[_vm._v(_vm._s(category.name))]),(
            category.id === 'discount' &&
              (_vm.detail.applied_pass ||
                _vm.detail.applied_promo ||
                _vm.detail.applied_voucher)
          )?_c('span',{staticClass:"cost-title ml-5"},[_c('span',{staticClass:"victory-text-alt",style:(("color: " + _vm.getColor + ";"))},[(_vm.detail.applied_voucher)?_c('RouterLink',{attrs:{"to":{
                name: 'VoucherDetails',
                params: { id: _vm.detail.applied_voucher.id },
              },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.discountDescription)+" ")]):_vm._e(),(_vm.detail.applied_promo)?_c('RouterLink',{attrs:{"to":{
                name: 'PromotionDetails',
                params: { id: _vm.detail.applied_promo.id },
              },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.discountDescription)+" ")]):_vm._e(),(_vm.detail.applied_pass)?_c('RouterLink',{attrs:{"to":{
                name: 'PassDetails',
                params: { id: _vm.detail.applied_pass.id },
              },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.discountDescription)+" ")]):_vm._e()],1)]):_vm._e(),(
            category.id === 'trip_cost' &&
              !category.isChild &&
              _vm.detail.child_trips &&
              _vm.detail.child_trips.length > 0
          )?_c('span',{staticClass:"rider-type-badge host"},[_vm._v("Host")]):_vm._e(),(category.id === 'trip_cost' && category.isChild)?_c('span',{staticClass:"rider-type-badge guest"},[_vm._v("Guest")]):_vm._e(),(category.id === 'trip_cost')?_c('p',{staticClass:"font-normal text-gray-700 text-xs"},[_vm._v(" "+_vm._s(_vm.getTripCostSubText(_vm.detail))+" ")]):_vm._e(),(
            category.id === 'penalty_amount' && _vm.detail.penalties.length > 0
          )?_c('p',{staticClass:"font-normal text-gray-700 text-xs"},[_vm._v(" "+_vm._s(_vm.getPenaltySubText(_vm.detail))+" ")]):_vm._e()]),_c('div',{staticClass:"justify-end text-right col-w-4"},[(_vm.hasDiscountedPrice(category.id))?_c('span',{staticClass:"cost-val regular-cost"},[_vm._v(_vm._s(category.regularPrice))]):_vm._e(),_c('span',{staticClass:"cost-val",style:(category.id == 'discount' ? 'color:#3ebc30' : 'color:#000000')},[_vm._v(_vm._s(category.price))])])])}),_c('div',{class:{
        'cost-list': true,
        'no-border': !_vm.detail.notes || _vm.detail.notes.length < 1,
      }},[_vm._m(1),_c('div',{staticClass:"col-w-3",staticStyle:{"text-align":"end"}},[_c('span',{staticClass:"cost-val"},[_vm._v(" "+_vm._s(_vm.getPaymentMethod)+" ")])])])],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"absolute",staticStyle:{"right":"5%","top":"5%"}},[_c('img',{staticClass:"w-32 h-32",attrs:{"src":require("@/assets/img/payment/refunded-seal.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-w-7"},[_c('i',{staticClass:"fas fa-credit-card"}),_c('span',{staticClass:"ml-3 cost-title"},[_vm._v("Payment")])])}]

export { render, staticRenderFns }