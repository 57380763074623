<template>
  <div :class="getTripStatusClass">
    <span>{{ getTripStatusText(status) }}</span>
    <i :class="icon" />
  </div>
</template>

<script>
import { getTripStatusColor, getTripStatusText } from '@/utils/TripStatus'
export default {
  name: 'TripCondition',
  components: {},
  props: {
    status: {
      type: String,
      required: true,
      validaotor(val) {
        let whitelist = ['R', 'A', 'U', 'O', 'V', 'C', 'T']
        return whitelist.includes(val)
      },
    },
    icon: {
      type: String,
      required: false,
    },
  },
  computed: {
    getTripStatusClass() {
      const color = this.getTripStatusColor(this.status)
      return `trip-status-pill-button ${color}`
    },
  },
  methods: {
    getTripStatusColor,
    getTripStatusText,
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/css/trip-status.scss';
</style>
