<template>
  <div
    class="flex flex-col items-center justify-between gap-1 gap-y-4 lg:gap-y-0 lg:flex-row"
  >
    <div class="lg:w-8/12">
      <span v-html="htmlTitle" />
    </div>

    <!-- <div class="flex items-center justify-start gap-5 lg:w-4/12">
      <template v-if="infoColumnPreset === 'default'">
        <div>
          <BatteryStatus :level="powerLevel" />
        </div>

        <div class="map-icon" @click="$emit('click-map', coords)">
          <i class="fas fa-map" />
        </div>
      </template>

      <template v-if="infoColumnPreset === 'riderPaymentMethod'">
        <div class="flex items-center lg:w-36">
          Card: &nbsp;
          <i
            :class="
              getCreditCardIcon(
                resolveProp(
                  'extra_data.context_data.payment_method_data.brand',
                  log
                )
              )
            "
          />
          &nbsp;****
          {{
            $truncate(
              resolveProp(
                'extra_data.context_data.default_payment_method',
                log
              ),
              -4
            ) || 'N/A'
          }}
        </div>

        <div class="inline-flex items-center w-24" v-tooltip.bottom="`Balance`">
          <i class="pr-1 text-blue-500 fa fa-wallet" />
          {{ log.user_currency_symbol + ' ' + log.user_balance }}
        </div>
      </template>

      <RequestStatus :code="responseCode" />
    </div> -->
  </div>
</template>

<script>
import { resolveProp, getCreditCardIcon } from '@/utils'
export default {
  components: {
    // BatteryStatus: () => import('@/components/badge/BatteryStatus.vue'),
    // RequestStatus: () => import('@/components/badge/RequestStatus.vue'),
  },

  props: {
    powerLevel: {
      type: Number,
      default: 0,
    },
    htmlTitle: {
      type: String,
      default: '',
    },
    responseCode: {
      type: Number,
      default: 0,
    },
    coords: {
      type: String,
      default: '0,0',
    },
    log: {
      type: Object,
      default: () => ({}),
    },
    infoColumnPreset: {
      type: String,
      default: 'default',
    },
  },

  methods: {
    resolveProp,
    getCreditCardIcon,
  },
}
</script>

<style lang="scss" scoped>
.map-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  background-color: #ff598a;
  cursor: pointer;
  width: 23px;
  height: 23px;
  background-color: #12ceda;
}
</style>
