<template>
  <div>
    <loading
      :height="45"
      :opacity="0.25"
      color="#000"
      v-if="tripAction.processing && isGuestTrip"
      :active="true"
      loader="bars"
      :is-full-page="true"
    />

    <t-dropdown variant="smActions" v-if="onRide">
      <template
        v-slot:trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
      >
        <button
          aria-label="Force Complete Dropdown"
          :class="`trip-status-pill-button focus:outline-none`"
          aria-haspopup="true"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <i
            v-if="lockedStatus === true"
            class="fas fa-lock fa-xs mr-2 text-gray-500"
          ></i>
          <i
            v-if="lockedStatus === false"
            class="fas fa-unlock fa-xs mr-2 text-blue-600"
          ></i>

          <i
            v-if="$acl.canUpdate('trips')"
            class="fas fa-angle-down fa-xs text-gray-500"
          ></i>
        </button>
      </template>

      <template v-slot:default="{ hide }">
        <div
          v-if="$acl.canUpdate('trips')"
          @click="hide()"
          class="py-1 mt-1 bg-white rounded-md shadow-md"
        >
          <t-dropdown-item
            @click="onForceComplete"
            class="text-oOrange hover:bg-orange-100"
          >
            Force Complete
          </t-dropdown-item>
        </div>
      </template>
    </t-dropdown>

    <div class="flex items-center" v-if="!onRide">
      <i
        v-if="lockedStatus === true"
        class="fas fa-lock fa-xs mr-2 text-gray-500"
      ></i>
      <i
        v-if="lockedStatus === false"
        class="fas fa-unlock fa-xs mr-2 text-blue-600"
      ></i>
    </div>

    <EdgeStack
      :id="`${tripId}-${tripAction.esId}`"
      :busy="tripAction.busy"
      :exit-button="{ text: 'Cancel', hidden: true }"
      :footer-class="{
        fixed: 'flex justify-end items-center px-6 pt-2 pb-5 w-full h-auto',
        append: '',
      }"
      :debug="true"
    >
      <template #header>
        <div class="text-xl font-bold">{{ tripAction.title }}</div>
      </template>

      <template #footer="{ close }">
        <div class="flex gap-5 items-center">
          <AppButton text="Cancel" variant="secondary" @click="close()" />

          <AppButton
            text="Confirm"
            :is-loading="tripAction.processing"
            @click="onEdgeConfirm"
          />
        </div>
      </template>

      <template #default>
        <div class="flex items-center px-3">
          <div class="w-14">
            <div
              class="flex justify-center items-center w-9 h-9 bg-gray-200 rounded-full"
            >
              <img
                :src="tripAction.data.user.profile_pic"
                class="object-contain w-9 h-9 rounded-full"
              />
            </div>
          </div>

          <div class="flex justify-between items-center py-4 w-full border-b">
            <div>
              <h5
                v-text="tripAction.data.user.full_name"
                class="text-base text-black"
              />
              <h6
                v-text="'UserID: ' + $truncate(tripAction.data.user.id, -5)"
                class="text-sm tracking-wide"
                style="color: #797979"
              />
            </div>

            <div
              class="text-right text-blue-600"
              v-text="`TripID: ${$truncate(tripAction.primaryKey, -5)}`"
            />
          </div>
        </div>

        <div class="flex items-center px-3">
          <div class="w-14">
            <div class="flex justify-center items-center w-9 h-9 rounded-full">
              <img src="@/assets/icon/rewind-circle.svg" />
            </div>
          </div>

          <div class="flex justify-between items-center py-4 w-full border-b">
            <div>
              <h5
                class="text-base text-black"
                v-text="`Forcibly lock vehicle`"
              />
              <h6
                v-text="
                  `If you select YES then System will lock the vehicle Forcefully.`
                "
                class="text-sm tracking-wide"
                style="color: #797979"
              />
            </div>

            <div class="flex items-center">
              <p
                class="w-8 text-sm text-oDark"
                v-text="tripAction.models.trigger_lock ? 'Yes' : 'No'"
              />
              <TToggle
                v-model="tripAction.models.trigger_lock"
                variant="success"
              />
            </div>
          </div>
        </div>

        <div class="flex items-center px-3">
          <div class="w-14">
            <div class="flex justify-center items-center w-9 h-9 rounded-full">
              <img src="@/assets/icon/plus-minus-box.svg" />
            </div>
          </div>

          <div class="flex justify-between items-center py-4 w-full border-b">
            <div>
              <h5
                class="text-base text-black"
                v-text="`Trigger payment for rider`"
              />
              <h6
                v-text="
                  `If you select Yes then Rider will be charged manually.`
                "
                class="text-sm tracking-wide"
                style="color: #797979"
              />
            </div>

            <div class="flex items-center">
              <p
                class="w-8 text-sm text-oDark"
                v-text="tripAction.models.trigger_payment ? 'Yes' : 'No'"
              />
              <TToggle
                v-model="tripAction.models.trigger_payment"
                variant="success"
              />
            </div>
          </div>
        </div>

        <div
          v-if="tripAction.models.trigger_payment"
          class="flex justify-end px-2 my-2"
        >
          <div
            class="py-4 max-w-md bg-gray-200 rounded-md border"
            style="min-width: 350px; background: #F3F3F3;"
          >
            <section class="flex gap-4 justify-between items-center px-4">
              <div class="-mt-6">Trip Duration (mins)</div>

              <div class="w-24">
                <AppInput
                  v-model="tripAction.models.riding_time_min"
                  type="number"
                />
              </div>
            </section>

            <section class="py-2 border-t border-gray-300">
              <div class="flex justify-between items-center py-1 px-4">
                <div class="w-full">
                  Reservation Fee
                </div>

                <div class="w-12">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.vehicle_reservation_fees
                  }}
                </div>
              </div>

              <div class="flex justify-between items-center py-1 px-4">
                <div class="w-full">
                  Unlock Fee
                </div>

                <div class="w-12">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.vehicle_unlock_fees
                  }}
                </div>
              </div>

              <div class="flex justify-between items-center py-1 px-4">
                <div class="w-full">
                  Trip Duration Fee ({{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.default_fees_per_minute
                  }}
                  x {{ tripAction.models.riding_time_min }} mins)
                </div>

                <div class="w-12">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.trip_amount
                  }}
                </div>
              </div>

              <div class="flex justify-between items-center py-1 px-4">
                <div class="w-full">
                  Discount
                </div>

                <div class="w-12 text-oGreen">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.discount_data
                  }}
                </div>
              </div>
            </section>

            <section class="py-2 border-t border-gray-300">
              <div class="flex justify-between items-center py-1 px-4">
                <div class="w-full">
                  Subtotal
                </div>

                <div class="w-12">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.total_amount
                  }}
                </div>
              </div>
              <div class="flex justify-between items-center py-1 px-4">
                <div class="w-full">Vat ({{ '0' }}%)</div>

                <div class="w-12">
                  {{ getTripActionCharges.currency_symbol + '0.00' }}
                </div>
              </div>
            </section>

            <section class="pt-3 border-t border-gray-300">
              <div class="flex justify-between items-center px-4">
                <div class="w-full">
                  Total
                </div>

                <div class="w-20 text-3xl text-center">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.total_amount
                  }}
                </div>
              </div>
            </section>
          </div>
        </div>

        <div class="flex px-3 py-3">
          <div class="w-14">
            <div class="flex items-center justify-center rounded-full w-9 h-9">
              <img src="@/assets/icon/note.svg" />
            </div>
          </div>
          <div class="w-full">
            <h5 class="text-base text-black" v-text="`Reason (Required)`" />
            <AppInput
              type="richselect"
              rules="required"
              name="reason"
              placeholder="Select a reason"
              value-attribute="value"
              text-attribute="text"
              :options="forceCompleteReason"
              hide-search-box
              v-model="tripAction.models.force_complete_reason"
            />
          </div>
        </div>
        <div class="flex px-3 py-3">
          <div class="w-14">
            <div class="flex items-center justify-center rounded-full w-9 h-9">
              <img src="@/assets/icon/note.svg" />
            </div>
          </div>
          <div class="w-full">
            <h5 class="text-base text-black" v-text="`Remarks (Required)`" />
            <AppInput type="textarea" v-model="tripAction.models.remarks" />
          </div>
        </div>
      </template>
    </EdgeStack>
  </div>
</template>

<script>
import EdgeStack from '@/components/modals/EdgeStack'
import TDropdownItem from '@/components/dropdown/TDropdownItem'
import { xMan } from '@/utils'

export default {
  name: 'TripDurationDropdown',

  components: {
    EdgeStack,
    TDropdownItem,
  },

  props: {
    onRide: {
      type: Boolean,
      required: false,
      default: false,
    },
    lockedStatus: {
      type: Boolean,
      required: false,
    },
    tripId: {
      type: String,
      required: true,
    },
    isGuestTrip: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,

      tripAction: {
        busy: false,
        esId: 'tripActionFromDurationESD',
        title: 'Force Complete Trip',

        primaryKey: '',
        processing: false, //

        models: {
          trigger_lock: false,
          trigger_payment: false,
          riding_time_min: 0, // in min
          force_complete_reason: '',
          remarks: '',
        },

        data: {
          from_lock_log: false,
          latest_lock_log: {},
          riding_time_mins: 0,

          user: {
            id: '--',
            full_name: '--',
            role_name: '--',
            profile_pic: 'https://via.placeholder.com/150',
          },

          pricing_info: {
            id: '--',
            bike_category: '-',
            currency_symbol: '~',
            vehicle_reservation_fees: 0,
            vehicle_unlock_fees: 0,
            default_fees_per_minute: 0,
            max_billing_per_ride: 0,
            discount_data: 0,
          },
        },
      },
      forceCompleteReason: [
        {
          text: 'Vehicle Locking Issue',
          value: 'VEHICLE_LOCKING_ISSUE',
        },
        {
          text: 'Vehicle Battery Died',
          value: 'VEHICLE_BATTERY_DIED',
        },
        {
          text: 'Vehicle Inactive',
          value: 'VEHICLE_INACTIVE',
        },
        {
          text: 'App Issues',
          value: 'APP_ISSUES',
        },
        {
          text: 'Other',
          value: 'OTHER',
        },
      ],
    }
  },

  methods: {
    onEdgeClose() {
      this.$edgeStack.close(`${this.tripId}-${this.tripAction.esId}`)
    },
    async executeAPI() {
      this.tripAction.processing = true
      const url = `/dashboard/trips/${this.tripId}/complete/`
      const { models } = this.tripAction
      const data = new xMan(models).toFormData()

      await this.$http
        .post(url, data)
        .then(() => {
          this.$notify({
            group: 'bottomLeft',
            type: 'success',
            title: `Force Completion Successful`,
            text: `Successfully completed the trip.`,
          })

          this.$emit('forceCompleted')
          this.onEdgeClose()
        })
        .catch((err) => {
          this.$notify({
            group: 'bottomLeft',
            type: 'danger',
            title: `Failed Action [Code: ${err?.response?.status}]`,
            text:
              err.response?.data?.detail ??
              `Failed to force complete the trip.`,
          })
        })
        .finally(() => {
          this.tripAction.processing = false
        })
    },
    async onForceComplete() {
      this.tripAction.primaryKey = this.tripId

      if (this.isGuestTrip) {
        this.executeAPI()

        return
      }

      const url = `/dashboard/trips/${this.tripId}/complete-preload/`

      this.tripAction.busy = true
      this.$edgeStack.open(`${this.tripId}-${this.tripAction.esId}`)
      await this.$http
        .get(url)
        .then((r) => {
          this.tripAction.data = r.data
          this.tripAction.models.riding_time_min = r.data.riding_time_mins
        })
        .catch((err) => {
          console.warn('tripActionPreloadErr', err, err.response)
        })
        .finally(() => {
          this.tripAction.busy = false
        })
    },
    onEdgeConfirm() {
      this.executeAPI()
    },
  },
}
</script>

<style lang="scss" scoped></style>
