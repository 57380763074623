<template>
  <div>
    <t-dropdown v-if="true" variant="smActions">
      <template
        v-slot:trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
      >
        <button
          aria-label="On Trip Dropdown"
          class="vld-parent trip-status-pill-button purple focus:outline-none"
          style="height: 35px; width: 170px"
          aria-haspopup="true"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <loading
            :height="25"
            :opacity="0"
            color="#fff"
            v-if="isLoading"
            :active="true"
            loader="bars"
            :is-full-page="false"
          />
          <template v-else>
            <span>On Trip</span>
            <template v-if="$acl.canUpdate('trips')"
              ><i class="fas fa-chevron-down"
            /></template>
          </template>
        </button>
      </template>

      <template v-slot:default="{ hide }">
        <div
          v-if="$acl.canUpdate('trips')"
          @click="hide()"
          class="py-1 mt-1 bg-white rounded-md shadow-md"
        >
          <t-dropdown-item @click="onTripAction({ tripId, action: 'open' })">
            Force Complete
          </t-dropdown-item>

          <!-- <t-dropdown-item @click="executeAction('complete')">
            End Trip
          </t-dropdown-item>

          <t-dropdown-item
            @click="executeAction('cancel')"
            class="text-oRed hover:bg-red-100"
          >
            Cancel Trip
          </t-dropdown-item> -->
        </div>
      </template>
    </t-dropdown>

    <TripStatusPillButton v-else :status="status" />

    <EdgeStack
      :id="tripAction.esId"
      :busy="tripAction.busy"
      :exit-button="{ text: 'Cancel', hidden: true }"
      :footer-class="{
        fixed: 'flex justify-end items-center px-6 pt-2 pb-5 w-full h-auto',
        append: '',
      }"
      :debug="true"
    >
      <template #header>
        <div class="text-xl font-bold">{{ tripAction.title }}</div>
      </template>

      <template #footer="{ close }">
        <div class="flex gap-5 items-center">
          <AppButton text="Cancel" variant="secondary" @click="close()" />

          <AppButton
            text="Confirm"
            :is-loading="tripAction.processing"
            @click="
              onTripAction({ tripId: tripAction.primaryKey, action: 'execute' })
            "
          />
        </div>
      </template>

      <template #default>
        <div class="flex items-center px-3">
          <div class="w-14">
            <div
              class="flex justify-center items-center w-9 h-9 bg-gray-200 rounded-full"
            >
              <img
                :src="tripAction.data.user.profile_pic"
                class="object-contain w-9 h-9 rounded-full"
              />
            </div>
          </div>

          <div class="flex justify-between items-center py-4 w-full border-b">
            <div>
              <h5
                v-text="tripAction.data.user.full_name"
                class="text-base text-black"
              />
              <h6
                v-text="'UserID: ' + $truncate(tripAction.data.user.id, -5)"
                class="text-sm tracking-wide"
                style="color: #797979"
              />
            </div>

            <div
              class="text-right text-blue-600"
              v-text="`TripID: ${$truncate(tripAction.primaryKey, -5)}`"
            />
          </div>
        </div>

        <div class="flex items-center px-3">
          <div class="w-14">
            <div class="flex justify-center items-center w-9 h-9 rounded-full">
              <img src="@/assets/icon/rewind-circle.svg" />
            </div>
          </div>

          <div class="flex justify-between items-center py-4 w-full border-b">
            <div>
              <h5
                class="text-base text-black"
                v-text="`Forcibly lock vehicle`"
              />
              <h6
                v-text="
                  `If you select YES then System will lock the vehicle Forcefully.`
                "
                class="text-sm tracking-wide"
                style="color: #797979"
              />
            </div>

            <div class="flex items-center">
              <p
                class="w-8 text-sm text-oDark"
                v-text="tripAction.models.trigger_lock ? 'Yes' : 'No'"
              />
              <TToggle
                v-model="tripAction.models.trigger_lock"
                variant="success"
              />
            </div>
          </div>
        </div>

        <div class="flex items-center px-3">
          <div class="w-14">
            <div class="flex justify-center items-center w-9 h-9 rounded-full">
              <img src="@/assets/icon/plus-minus-box.svg" />
            </div>
          </div>

          <div class="flex justify-between items-center py-4 w-full border-b">
            <div>
              <h5
                class="text-base text-black"
                v-text="`Trigger payment for rider`"
              />
              <h6
                v-text="
                  `If you select Yes then Rider will be charged manually.`
                "
                class="text-sm tracking-wide"
                style="color: #797979"
              />
            </div>

            <div class="flex items-center">
              <p
                class="w-8 text-sm text-oDark"
                v-text="tripAction.models.trigger_payment ? 'Yes' : 'No'"
              />
              <TToggle
                v-model="tripAction.models.trigger_payment"
                variant="success"
              />
            </div>
          </div>
        </div>

        <div
          v-if="tripAction.models.trigger_payment"
          class="flex justify-end px-2 my-2"
        >
          <div
            class="py-4 max-w-md bg-gray-200 rounded-md border"
            style="min-width: 350px; background: #F3F3F3;"
          >
            <section class="flex gap-4 justify-between items-center px-4">
              <div class="-mt-6">Trip Duration (mins)</div>

              <div class="w-24">
                <AppInput
                  v-model="tripAction.models.riding_time_min"
                  type="number"
                />
              </div>
            </section>

            <section class="py-2 border-t border-gray-300">
              <div class="flex justify-between items-center py-1 px-4">
                <div class="w-full">
                  Reservation Fee
                </div>

                <div class="w-12">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.vehicle_reservation_fees
                  }}
                </div>
              </div>

              <div class="flex justify-between items-center py-1 px-4">
                <div class="w-full">
                  Unlock Fee
                </div>

                <div class="w-12">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.vehicle_unlock_fees
                  }}
                </div>
              </div>

              <div class="flex justify-between items-center py-1 px-4">
                <div class="w-full">
                  Trip Duration Fee ({{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.default_fees_per_minute
                  }}
                  x {{ tripAction.models.riding_time_min }} mins)
                </div>

                <div class="w-12">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.trip_amount
                  }}
                </div>
              </div>

              <div class="flex justify-between items-center py-1 px-4">
                <div class="w-full">
                  Discount
                </div>

                <div class="w-12 text-oGreen">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.discount_data
                  }}
                </div>
              </div>
            </section>

            <section class="py-2 border-t border-gray-300">
              <div class="flex justify-between items-center py-1 px-4">
                <div class="w-full">
                  Subtotal
                </div>

                <div class="w-12">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.total_amount
                  }}
                </div>
              </div>
              <div class="flex justify-between items-center py-1 px-4">
                <div class="w-full">Vat ({{ '0' }}%)</div>

                <div class="w-12">
                  {{ getTripActionCharges.currency_symbol + '0.00' }}
                </div>
              </div>
            </section>

            <section class="pt-3 border-t border-gray-300">
              <div class="flex justify-between items-center px-4">
                <div class="w-full">
                  Total
                </div>

                <div class="w-20 text-3xl text-center">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.total_amount
                  }}
                </div>
              </div>
            </section>
          </div>
        </div>

        <div class="flex px-3 py-3">
          <div class="w-14">
            <div class="flex items-center justify-center rounded-full w-9 h-9">
              <img src="@/assets/icon/note.svg" />
            </div>
          </div>
          <div class="w-full">
            <h5 class="text-base text-black" v-text="`Reason (Required)`" />
            <AppInput
              type="richselect"
              rules="required"
              name="reason"
              placeholder="Select a reason"
              value-attribute="value"
              text-attribute="text"
              :options="forceCompleteReason"
              hide-search-box
              v-model="tripAction.models.force_complete_reason"
            />
          </div>
        </div>
        <div class="flex px-3 py-3">
          <div class="w-14">
            <div class="flex items-center justify-center rounded-full w-9 h-9">
              <img src="@/assets/icon/note.svg" />
            </div>
          </div>
          <div class="w-full">
            <h5 class="text-base text-black" v-text="`Remarks (Required)`" />
            <AppInput type="textarea" v-model="tripAction.models.remarks" />
          </div>
        </div>
      </template>
    </EdgeStack>
  </div>
</template>

<script>
import { TripConfig } from '@/config/TripConfig'
import TDropdownItem from '@/components/dropdown/TDropdownItem'
import { getTripStatusText } from '@/utils/TripStatus'
import TripStatusPillButton from '@/components/button/TripStatusPillButton.vue'
import { xMan } from '@/utils'
import EdgeStack from '@/components/modals/EdgeStack'

export default {
  name: 'TripCompletedDropdown',
  components: {
    EdgeStack,
    TDropdownItem,
    TripStatusPillButton,
  },
  props: {
    tripId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      status: 'O',

      tripAction: {
        busy: false,
        esId: 'tripActionESD',
        title: 'Force Complete Trip',

        primaryKey: '',
        processing: false, //

        models: {
          trigger_lock: false,
          trigger_payment: false,
          riding_time_min: 0, // in min
          force_complete_reason: '',
          remarks: '',
        },

        data: {
          from_lock_log: false,
          latest_lock_log: {},
          riding_time_mins: 0,

          user: {
            id: '--',
            full_name: '--',
            role_name: '--',
            profile_pic: 'https://via.placeholder.com/150',
          },

          pricing_info: {
            id: '--',
            bike_category: '-',
            currency_symbol: '~',
            vehicle_reservation_fees: 0,
            vehicle_unlock_fees: 0,
            default_fees_per_minute: 0,
            max_billing_per_ride: 0,
            discount_data: 0,
          },
        },
      },
      forceCompleteReason: [
        {
          text: 'Vehicle Locking Issue',
          value: 'VEHICLE_LOCKING_ISSUE',
        },
        {
          text: 'Vehicle Battery Died',
          value: 'VEHICLE_BATTERY_DIED',
        },
        {
          text: 'Vehicle Inactive',
          value: 'VEHICLE_INACTIVE',
        },
        {
          text: 'App Issues',
          value: 'APP_ISSUES',
        },
        {
          text: 'Other',
          value: 'OTHER',
        },
      ],
    }
  },
  computed: {
    getStatusText() {
      return getTripStatusText(getTripStatusText)
    },
    getTripActionCharges() {
      let p = this.tripAction.data.pricing_info
      let t = this.tripAction.models.riding_time_min

      p.vehicle_reservation_fees =
        Number(p.vehicle_reservation_fees).toFixed(2) || Number(0).toFixed(2)

      p.vehicle_unlock_fees =
        Number(p.vehicle_unlock_fees).toFixed(2) || Number(0).toFixed(2)

      p.discount_data =
        Number(p.discount_data).toFixed(2) || Number(0).toFixed(2)

      let trip_amount_raw = Number(p.default_fees_per_minute) * Number(t)
      let trip_amount = Number(trip_amount_raw).toFixed(2)

      let total_amount = (
        Number(trip_amount) +
        Number(p.vehicle_reservation_fees) +
        Number(p.vehicle_unlock_fees) -
        Number(p.discount_data)
      ).toFixed(2)

      return { ...p, trip_amount, total_amount }
    },
  },
  methods: {
    async executeAction(actionId) {
      let title = ''
      let message = ''
      let endpoint = ''

      if (actionId === 'complete') {
        title = 'Trip Ended'
        message = 'The Trip is marked as completed'
        endpoint = TripConfig.api.actions.complete(this.tripId)
      } else if (actionId === 'cancel') {
        title = 'Trip Cancelled'
        message = 'The trip is marked as cancelled'
        endpoint = TripConfig.api.actions.cancel(this.tripId)
      }

      this.isLoading = true
      await this.$http
        .post(endpoint)
        .then(() => {
          if (actionId === 'complete') {
            this.status = 'C'
          } else if (actionId === 'cancel') {
            this.status = 'A'
          }
          this.$store.dispatch('lastTrip/saveStatus', this.status)
          // alert(this.$store.getters["lastTrip/getStatus"]);

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: title,
              text: message,
            },
            3000
          )
        })
        .catch((err) => {
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Error',
              text: `Action failed to execute [ERR:${err.response.status}]`,
            },
            3000
          )
        })
        .finally(() => {
          this.isLoading = false
        })
      return true
    },

    async onTripAction({ tripId = null, action = 'open' } = {}) {
      this.tripAction.primaryKey = tripId
      console.log('onTripAction', tripId, action)

      if (action === 'open') {
        const url = `/dashboard/trips/${tripId}/complete-preload/`

        this.tripAction.busy = true
        this.$edgeStack.open(this.tripAction.esId)
        await this.$http
          .get(url)
          .then((r) => {
            console.log('preLoadRes', r.data)
            this.tripAction.data = r.data
            this.tripAction.models.riding_time_min = r.data.riding_time_mins
          })
          .catch((err) => {
            console.warn('tripActionPreloadErr', err, err.response)
          })
          .finally(() => {
            this.tripAction.busy = false
          })
        return
      }

      if (action === 'close') {
        this.$edgeStack.close(this.tripAction.esId)
        return
      }

      if (action === 'execute') {
        this.tripAction.processing = true
        const url = `/dashboard/trips/${tripId}/complete/`
        const { models } = this.tripAction
        const data = new xMan(models).toFormData()

        await this.$http
          .post(url, data)
          .then((r) => {
            console.log(r.data)
            this.$notify({
              group: 'bottomLeft',
              type: 'success',
              title: `Force Complete Trip`,
              text: r.data?.detail ?? `Successfully completed the action.`,
            })

            this.$emit('force-completed')
            this.onTripAction({ action: 'close' })
            // todo: save status
            this.$store.dispatch('lastTrip/saveStatus', this.status)

            // this.$store.dispatch('fsTable/fetchData')
          })
          .catch((err) => {
            this.$notify({
              group: 'bottomLeft',
              type: 'danger',
              title: `Failed Action [Code: ${err?.response?.status}]`,
              text:
                err.response?.data?.detail ??
                `Failed to execute force complete.`,
            })
            console.log('tripActionErr', err, err.data)
          })
          .finally(() => {
            this.tripAction.processing = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/trip-status';
</style>
