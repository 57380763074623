<template>
  <section class="mb-2 bg-white rounded-md shadow overflow-auto">
    <div class="relative">
      <div class="items-center w-full h-full place-content-center flex">
        <div class="my-6">
          <div class="flex items-center">
            <span class="block cost-total-title"
              >{{ detail.currency.symbol }} {{ getTotalAmount }}
            </span>
            <img
              src="@/assets/icon/info.svg"
              class="cursor-pointer ml-1"
              @click="onPricingPlanHistoryModalReq()"
            />
          </div>
        </div>
      </div>
      <template v-if="isRefunded">
        <div class="absolute" style="right: 5%; top: 5%">
          <img src="@/assets/img/payment/refunded-seal.png" class="w-32 h-32" />
        </div>
      </template>
      <TripNotesView :data="detail" />
    </div>

    <div class="row">
      <div class="grid h-24 grid-cols-2">
        <div
          class="pl-10 place-content-center cost-grid-item border-right flex-col text-left"
        >
          <div class="cost-title-no-color text-gray-500">
            {{ items[0].title }}
          </div>
          <div class="cost-title-no-color text-oDark">
            {{ items[0].subTitle }}
          </div>
        </div>
        <div
          class="pr-10 place-content-center cost-grid-item flex-col text-right"
        >
          <div class="cost-title-no-color text-gray-500">
            {{ items[1].title }}
          </div>
          <div class="cost-title-no-color text-oDark">
            {{ items[1].subTitle }}
          </div>
        </div>
      </div>
    </div>

    <div class="px-6">
      <div
        class="cost-list"
        v-for="(category, index) in getCostCategories"
        :key="index"
      >
        <div :class="{ 'col-w-6': true, 'flex': category.id === 'discount' }">
          <span
            :class="{
              'cost-title': true,
              'flex-grow': category.id === 'discount',
            }"
            :style="
              category.id === 'discount' ? 'color:#3ebc30' : 'color:#000000'
            "
            >{{ category.name }}</span
          >
          <span
            class="cost-title ml-5"
            v-if="
              category.id === 'discount' &&
                (detail.applied_pass ||
                  detail.applied_promo ||
                  detail.applied_voucher)
            "
          >
            <span class="victory-text-alt" :style="`color: ${getColor};`">
              <RouterLink
                v-if="detail.applied_voucher"
                :to="{
                  name: 'VoucherDetails',
                  params: { id: detail.applied_voucher.id },
                }"
                target="_blank"
              >
                {{ discountDescription }}
              </RouterLink>
              <RouterLink
                v-if="detail.applied_promo"
                :to="{
                  name: 'PromotionDetails',
                  params: { id: detail.applied_promo.id },
                }"
                target="_blank"
              >
                {{ discountDescription }}
              </RouterLink>
              <RouterLink
                v-if="detail.applied_pass"
                :to="{
                  name: 'PassDetails',
                  params: { id: detail.applied_pass.id },
                }"
                target="_blank"
              >
                {{ discountDescription }}
              </RouterLink>
            </span>
          </span>
          <span
            class="rider-type-badge host"
            v-if="
              category.id === 'trip_cost' &&
                !category.isChild &&
                detail.child_trips &&
                detail.child_trips.length > 0
            "
            >Host</span
          >
          <span
            class="rider-type-badge guest"
            v-if="category.id === 'trip_cost' && category.isChild"
            >Guest</span
          >
          <p
            v-if="category.id === 'trip_cost'"
            class="font-normal text-gray-700 text-xs"
          >
            {{ getTripCostSubText(detail) }}
          </p>
          <p
            v-if="
              category.id === 'penalty_amount' && detail.penalties.length > 0
            "
            class="font-normal text-gray-700 text-xs"
          >
            {{ getPenaltySubText(detail) }}
          </p>
        </div>
        <div class="justify-end text-right col-w-4">
          <span
            class="cost-val regular-cost"
            v-if="hasDiscountedPrice(category.id)"
            >{{ category.regularPrice }}</span
          >

          <span
            class="cost-val"
            :style="
              category.id == 'discount' ? 'color:#3ebc30' : 'color:#000000'
            "
            >{{ category.price }}</span
          >
        </div>
      </div>

      <div
        :class="{
          'cost-list': true,
          'no-border': !detail.notes || detail.notes.length < 1,
        }"
      >
        <div class="col-w-7">
          <i class="fas fa-credit-card"></i>
          <span class="ml-3 cost-title">Payment</span>
        </div>
        <div class="col-w-3" style="text-align: end">
          <span class="cost-val">
            {{ getPaymentMethod }}
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import TripMultiIcon from './TripMultiIcon.vue'
// import CostMessageBox from '@/composites/trip/shared/CostMessageBox'
import {
  getFormattedDateTime,
  getHumanReadableDuration,
} from '@/utils/datetime'
// import OtoDrowDownIcon from "@/components/ui/OtoDrowDownIcon";
import { getFormattedDate } from '@/utils/datetime'

const PASS_TYPES = {
  MM: 'Monthly',
  YY: 'Yearly',
  DD: 'Daily',
  WE: 'Weekly',
}

function getTaxFormattedTitle(taxRate) {
  if (taxRate) {
    return `VAT ${parseFloat(taxRate).toFixed(0)}%`
  }

  return 'VAT'
}

export default {
  components: {
    // TripMultiIcon,
    // CostMessageBox,
    //OtoDrowDownIcon
    TripNotesView: () =>
      import('@/composites/trip/details/blocks/timeline/TripNotesView.vue'),
  },
  props: {
    detail: {
      required: false,
      default: null,
    },
    symbol: {
      required: false,
    },
  },
  mounted() {
    console.log('detail = ', this.detail)

    this.items.forEach((item) => {
      if (item.id === 'transactionTime') {
        item.subTitle = this.detail.invoice
          ? getFormattedDateTime(this.detail.invoice.date, 'D MMM YYYY, h:mm a')
          : '--'
      } else if (item.id === 'invoiceID') {
        item.subTitle = this.detail.invoice
          ? `#${this.detail.invoice.id.substr(
              this.detail.invoice.id.length - 5
            )}`
          : '--'
      }
    })

    for (let i = 0; i < this.getCostCategories.length; ++i) {
      if (this.getCostCategories[i].id == 'discount') {
        this.getCostCategories[i].price = this.getFormattedPrice(
          this.detail.discount
        )
      } else if (this.getCostCategories[i].id == 'unlock_charge') {
        let totalPrice = this.detail.unlock_charge
        let originalTotalPrice = this.detail.original_unlock_cost

        if (this.detail.child_trips) {
          for (let i = 0; i < this.detail.child_trips.length; ++i) {
            totalPrice += this.detail.child_trips[i].unlock_charge
            originalTotalPrice += this.detail.child_trips[i]
              .original_unlock_cost
          }
        }

        this.getCostCategories[i].price = this.getFormattedPrice(totalPrice)

        if (this.detail.original_unlock_cost) {
          this.getCostCategories[i].regularPrice = this.getFormattedPrice(
            originalTotalPrice
          )
        }
      } else if (this.getCostCategories[i].id == 'reservation_charge') {
        let totalPrice = this.detail.reservation_charge
        let originalTotalPrice = this.detail.original_reservation_cost

        if (this.detail.child_trips) {
          for (let i = 0; i < this.detail.child_trips.length; ++i) {
            totalPrice += this.detail.child_trips[i].reservation_charge
            originalTotalPrice += this.detail.child_trips[i]
              .original_reservation_cost
          }
        }

        this.getCostCategories[i].price = this.getFormattedPrice(totalPrice)

        if (this.detail.original_reservation_cost) {
          this.getCostCategories[i].regularPrice = this.getFormattedPrice(
            originalTotalPrice
          )
        }
      } else if (this.getCostCategories[i].id == 'trip_cost') {
        this.getCostCategories[i].name = this.getTripCostName(
          this.getCostCategories[i].id,
          this.detail
        )
        this.getCostCategories[i].price = this.getFormattedPrice(
          this.detail.cost
        )

        if (this.detail.child_trips) {
          for (let j = 0; j < this.detail.child_trips.length; j++, i++) {
            // this.cost_categories.push(this.cost_categories[i + j + 1])
            this.getCostCategories.splice(i + 1, 0, {
              id: 'trip_cost',
              name: this.getTripCostName(
                this.getCostCategories[i].id,
                this.detail.child_trips[j]
              ),
              price: this.getFormattedPrice(this.detail.child_trips[j].cost),
              regularPrice: '0',
              isChild: true,
            })
          }
        }
      } else if (this.getCostCategories[i].id == 'pause_time_cost') {
        this.getCostCategories[i].name = this.getPauseTimeCostName(
          this.getCostCategories[i].id,
          this.detail
        )
        this.getCostCategories[i].price = this.getFormattedPrice(
          this.detail.pause_cost
        )
      } else if (this.getCostCategories[i].id == 'tax_amount') {
        this.getCostCategories[i].price = this.getFormattedPrice(
          this.detail.tax_amount
        )

        this.getCostCategories[i].name = getTaxFormattedTitle(
          this.detail.tax_rate
        )
      } else if (this.getCostCategories[i].id == 'sub_total') {
        let totalPrice = this.detail.cost
        let totalUnlockPrice = this.detail.unlock_charge
        let totalReservationPrice = this.detail.reservation_charge

        if (this.detail.child_trips) {
          for (let i = 0; i < this.detail.child_trips.length; ++i) {
            totalPrice += this.detail.child_trips[i].cost
            totalUnlockPrice += this.detail.child_trips[i].unlock_charge
            totalReservationPrice += this.detail.child_trips[i]
              .reservation_charge
          }
        }
        this.getCostCategories[i].price = this.getFormattedPrice(
          totalPrice + totalUnlockPrice + totalReservationPrice
        )
      } else if (this.getCostCategories[i].id == 'penalty_amount') {
        this.getCostCategories[i].price = `-${parseFloat(
          this.detail.penalties[0].deduct_amount
        ).toFixed(2)}`
      }
    }
    console.log('cost', this.cost_categories)
  },
  computed: {
    getCostCategories() {
      if (this.detail.reservation_charge == 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.cost_categories = this.cost_categories.filter(
          (item) => item.id !== 'reservation_charge'
        )
        return this.cost_categories
      } else {
        return this.cost_categories
      }
    },
    getTotalAmount() {
      if (this.detail.invoice) {
        return this.detail.invoice.amount === 0
          ? '-.--'
          : parseFloat(this.detail.invoice.amount).toFixed(2)
      } else {
        return '-.--'
      }
    },
    getColor() {
      if (this.detail.pass_applied) {
        return `#f07300`
      }
      if (this.detail.promo_applied) {
        return `#8200ff`
      }
      if (this.detail.voucher_applied) {
        return `#0fb400`
      }
      return `#000000`
    },
    promoDescription() {
      const promo = this.detail.applied_promo
      const expiry = getFormattedDate(promo.end_date, 'D MMM, YYYY')
      let message = ''

      if (promo.promotion_category === 'RD') {
        message = `${parseFloat(promo.percentage).toFixed(0)}% off`
      } else if (promo.promotion_category === 'FR') {
        message = 'Free ride'
      } else if (promo.promotion_category === 'FD') {
        message = `${this.currencySymbol}${parseFloat(
          promo.flat_discount_amount
        ).toFixed(0)} off`
      }

      return `${message}. Valid till ${expiry}.`
    },
    passDescription() {
      const pass = this.detail.applied_pass
      const passPackage = pass.package
      const title = `${PASS_TYPES[passPackage.period]} Pass`
      const expiry = getFormattedDate(passPackage.end_date, 'D MMM, YYYY')
      return `${title}. Valid till ${expiry}.`
    },
    voucherDescription() {
      const voucher = this.detail.applied_voucher
      const title = `${voucher.package.description ||
        voucher.package.title ||
        ''}`
      const expiry = getFormattedDate(voucher.expire_date, 'D MMM, YYYY')
      return `${title}. Valid till ${expiry}.`
    },
    discountDescription() {
      const promo = this.detail.applied_promo

      if (promo) {
        return `${promo.code}`
      }

      const voucher = this.detail.applied_voucher

      if (voucher) {
        return `${voucher.package.title}`
      }

      const pass = this.detail.applied_pass

      if (pass) {
        const passPackage = pass.package
        const title = `${PASS_TYPES[passPackage.period]} Pass`
        return `${title}`
      }

      return ''
    },
    currencySymbol() {
      return this.detail?.currency?.symbol || this.$org.default_currency.symbol
    },
    isRefunded() {
      return this.detail?.invoice && this.detail.invoice.is_refunded
    },
    getPaymentMethod() {
      const sources = []

      if (!this.detail.invoice) {
        sources.push('eWallet')
      } else {
        const paymentMethod = this.detail.invoice?.payment_method

        if (paymentMethod) {
          sources.push(`${paymentMethod.brand} ${paymentMethod.number}`)
        } else {
          sources.push('eWallet')
        }
      }

      if (this.detail.subscription_charge_amount !== null) {
        sources.push('Pass')
      }

      // if (this.detail.applied_promo && !this.detail.invoice) {
      //   sources.push('Promotion')
      // }

      // if (this.detail.applied_voucher && !this.detail.invoice) {
      //   sources.push('Voucher')
      // }

      return sources.join(', ')
    },
  },
  data() {
    return {
      onTripDurationStr: null,
      cost_categories: [
        {
          id: 'reservation_charge',
          name: 'Reservation ',
          price: '0',
          regularPrice: '0',
        },
        {
          id: 'unlock_charge',
          name: 'Unlock Charge',
          price: '0',
          regularPrice: '0',
        },
        {
          id: 'trip_cost',
          name: 'Trip Cost',
          price: '0',
          regularPrice: '0',
        },
        {
          id: 'pause_time_cost',
          name: 'Pause Time Cost',
          price: '0',
        },
        {
          id: 'sub_total',
          name: 'SubTotal',
          price: '0',
        },
        {
          id: 'discount',
          name: 'Discount',
          price: '0',
        },
        {
          id: 'tax_amount',
          name: 'VAT',
          price: '0',
        },
        {
          id: 'penalty_amount',
          name: 'Applied Penalty Amount',
          price: '0.00',
        },
      ],
      items: [
        {
          id: 'transactionTime',
          icon_class: 'fas fa-thumbs-up category-icon',
          title: 'Transaction Time',
          subTitle: 'Rated',
          color: '#3ebc30',
        },
        {
          id: 'invoiceID',
          icon_class: 'fas fa-road category-icon',
          title: 'Invoice ID',
          subTitle: 'Trip Duration',
          color: '#149cff',
        },
      ],
    }
  },
  methods: {
    onPricingPlanHistoryModalReq() {
      this.$modal.show('pricingPlanHistoryModal')
    },
    getFormattedPrice(rawPrice) {
      let _price = rawPrice

      if (typeof rawPrice !== 'number') {
        _price = parseFloat(rawPrice).toFixed(2)

        if (isNaN(_price)) {
          return '--'
        }
      } else {
        _price = rawPrice.toFixed(2)
      }

      return `${_price}`
    },
    getTripsRate(trip) {
      if (trip.riding_time > trip.billing_unit_secs) {
        return this.getFormattedPrice(trip.rate_per_minute)
      } else {
        return this.getFormattedPrice(trip.first_unit_fees)
      }
    },
    getTripCostName(costId, trip) {
      if (costId !== 'trip_cost') {
        return '--'
      }

      return trip.riding_time &&
        trip.rate_per_minute !== null &&
        trip.rate_per_minute >= 0
        ? `Trip Cost (${getHumanReadableDuration(trip.riding_time)} *  ${
            this.currencySymbol
          } ${trip.discounted_rate_per_minute || this.getTripsRate(trip)})`
        : 'Trip Cost'
    },
    getTripCostSubText(trip) {
      return `First Unit  ${this.currencySymbol}${trip.first_unit_fees}/${
        trip.billing_unit_secs / 60 === 1
          ? 'min'
          : `${trip.billing_unit_secs / 60}min`
      } rate applied`
    },
    getPenaltySubText(trip) {
      return trip.penalties[0].title
    },
    getPauseTimeCostName(costId, trip) {
      if (costId !== 'pause_time_cost') {
        return '--'
      }
      return trip.pause_time_secs_till_now &&
        trip.pause_rate_per_minute !== null &&
        trip.pause_entries.length > 0
        ? `Pause Time Cost (${getHumanReadableDuration(
            trip.pause_time_secs_till_now
          )} *  ${this.currencySymbol} ${trip.pause_rate_per_minute})`
        : 'Pause Time Cost '
    },
    hasDiscountedPrice(categoryId) {
      if (
        categoryId === 'unlock_charge' &&
        this.detail.original_unlock_cost &&
        this.detail.applied_pass
      ) {
        return true
      }

      if (
        categoryId === 'reservation_charge' &&
        this.detail.original_reservation_cost &&
        this.detail.applied_pass
      ) {
        return true
      }

      // if (categoryId === 'unlock_charge' && this.detail.original_unlock_cost) {
      //   return true
      // }

      return false
    },
    // getFormattedPrice(price) {
    //   return parseFloat(price) >= 0
    //     ? this.detail?.currency?.symbol + ' ' + parseFloat(price).toFixed(2)
    //     : '--'
    // },
    getDate(date) {
      var time = new Date(date)

      var month = time.getMonth()
      var days = time.getDate()
      var year = time.getFullYear()
      var arr_month = new Array()
      arr_month[0] = 'January'
      arr_month[1] = 'February'
      arr_month[2] = 'March'
      arr_month[3] = 'April'
      arr_month[4] = 'May'
      arr_month[5] = 'June'
      arr_month[6] = 'July'
      arr_month[7] = 'August'
      arr_month[8] = 'September'
      arr_month[9] = 'October'
      arr_month[10] = 'November'
      arr_month[11] = 'December'

      return days + ' ' + arr_month[month] + ' , ' + year
    },
  },
}
</script>

<style lang="scss" scoped>
.rider-type-badge {
  @apply bg-blue-600;

  color: #fff;
  font-weight: 400 !important;
  font-size: 9.75px !important;
  text-transform: uppercase;
  padding: 2px 6px;
  border-radius: 8px;
  margin-left: 15px !important;

  &.guest {
    @apply bg-gray-400;
  }
}

.notes-wrapper {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.victory-text-alt {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: 'Roboto';
  width: 100%;
  text-decoration: underline;
}
</style>
