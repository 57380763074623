<template>
  <div class="mb-3">
    <section class="w-full px-6 py-3 bg-white rounded-md shadow text-center">
      <span class="font-extrabold text-2xl " v-text="`Trip Timeline`" />
    </section>
    <TimelineGroup :isLoading="isLoading" :logs="logs" timeline-type="trip" />
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import TimelineGroup from '@/components/timeline-new-final/TimelineGroup.vue'

export default {
  name: 'TimelineHOC',

  props: {
    tripId: {
      type: String,
      required: true,
    },
  },

  components: {
    TimelineGroup,
  },

  data() {
    return {
      isLoading: false,
      logs: [],
    }
  },

  async created() {
    await this.fetchLogs()
  },

  methods: {
    async fetchLogs() {
      this.isLoading = true
      const url = useEndpoints.trip.tripRequestLogs(this.tripId)

      await this.$http
        .get(url)
        .then((res) => {
          console.log('fetchLogs res = ', res.data)
          this.logs = res.data
        })
        .catch((err) => {
          console.error('fetchLogs err = ', err, err.response.data)
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>
